/**
 * @description 松尾考哲 dialog
 * @author zqsun
 * @date 2024/7/8
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import FlexCenter from 'react-flex-center';
import { TeamImage } from 'src/ts/img/teams/team-image';
import { useDialogStore } from 'src/ts/stores/dialog-store';
import DialogCloseIcon from 'src/tsx/views/components/atoms/DialogCloseIcon';
import styles from './Staff.module.scss';

const Staff8 = () => {
  /* state start*/
  const { setShowMember8Dialog } = useDialogStore();
  /* state end*/

  /* function start*/
  const closeDialog = () => {
    setShowMember8Dialog(false);
  };
  /* function end*/
  return (
    <div id='Staff8' className={styles.root}>
      <FlexCenter style={{ justifyContent: 'flex-end' }}>
        <DialogCloseIcon onClick={closeDialog} />
      </FlexCenter>

      <Grid
        container
        spacing={4}
        className={'h-full'}
        style={{
          alignItems: 'center',
          marginTop: '-50px',
        }}>
        {/* text part start*/}
        <Grid item xs={12} md={6} className={'h-full'}>
          <FlexCenter className={'h-full'}>
            <div className={'h-full'}>
              <article className={styles.article0}>
                慶応義塾大学 法学部
                政治学科卒業後、テレビ局のアナウンサーやニュースキャスターの経験を積んだ後、Londonに留学。福島復興支援のチャリティープロジェクトを英国貴族院議員らの協力を経て、アジア人初となる英国国会議事堂にてイベント開催した経験を経て、帰国後はLuxury業界で主にブランド・マーケティングやCSRに従事。東京大学大学院にてLuxuryとCSRの研究を行う。
              </article>
              <div
                style={{
                  marginTop: '30px',
                }}>
                <span className={styles.position}>Massive Advisor</span>
                <span
                  style={{
                    fontSize: '28px',
                    lineHeight: '42px',
                    marginLeft: '10px',
                  }}>
                  舟引美咲
                </span>
              </div>
            </div>
          </FlexCenter>
        </Grid>
        {/* text part end*/}

        {/* photo part start*/}
        <Grid item xs={12} md={6}>
          <FlexCenter>
            <img src={TeamImage.staff8} alt={''} className={styles.staffImage0} />
          </FlexCenter>
        </Grid>
        {/* photo part end*/}
      </Grid>
    </div>
  );
};

export default Staff8;
