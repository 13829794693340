/**
 * @description news detail 5
 * @author zqsun
 * @date 2024/7/22
 * @version 1.0
 */

import FlexCenter from 'react-flex-center';
import { Link } from 'react-router-dom';
import { NewsImage } from 'src/ts/img/news/news-image';
import { APP_URLS } from 'src/ts/router/url-router';
import styles from './NewsDetail.module.scss';

const NewsDetail5 = () => {
  return (
    <div id='NewsDetail5' className={styles.root}>
      <div className={'mainRoot'}>
        <h2 className={styles.title}>News</h2>
      </div>
      <div
        style={{
          backgroundColor: 'white',
        }}>
        <div
          className={'mainRoot'}
          style={{
            paddingTop: '50px',
            paddingBottom: '50px',
          }}>
          <p className={'font-bold mb-1.5'}>2022.3.10</p>
          <h1 className={styles.newsTitle}>シードラウンド調達額が累計2.5億円達成のご報告</h1>

          <article className={styles.newsText}>
            Super Massive Global 株式会社 (読み：スーパー マッシブ グローバル、本社：東京都港区、CEO：内藤慎人)
            はシードラウンドにて、CBC株式会社など計8社から、2022年2月18日に1.1億円の資金調達を完了したことをお知らせいたします。
            なお2021年には株式会社Zero-Ten（ゼロテン）など2度にわたる資金調達を受けており、このたび3度目の調達により累計調達額は2.5億円となりました。
            <br />
            PR times:{' '}
            <a
              href={'https://prtimes.jp/main/html/rd/p/000000001.000097615.html'}
              target={'_blank'}
              rel={'noreferrer'}
              style={{
                color: 'blue',
              }}>
              <u>https://prtimes.jp/main/html/rd/p/000000001.000097615.html</u>
            </a>
          </article>

          <FlexCenter style={{ justifyContent: 'space-evenly' }}>
            <Link to={APP_URLS.NEWS_DETAIL4}>
              <FlexCenter>
                <img src={NewsImage.newsPrev} alt='news' />
                <p
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}>
                  前ページ
                </p>
              </FlexCenter>
            </Link>
            <div>
              <Link to={APP_URLS.NEWS}>記事一覧</Link>
            </div>
            <Link to={APP_URLS.NEWS_DETAIL6}>
              <FlexCenter>
                <p
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}>
                  次ページ
                </p>
                <img src={NewsImage.newsNext} alt='news' />
              </FlexCenter>
            </Link>
          </FlexCenter>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail5;
