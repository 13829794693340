import 'new-verse-front-library/style/index.scss';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import DialogView from 'src/tsx/views/components/atoms/DialogView';
import './App.scss';
import { routers } from './ts/router/router-index';

const App = () => {
  return (
    <div id='App'>
      <BrowserRouter>
        <DialogView>
          <Switch>
            {routers.map((router, index) => (
              <Route path={router.path} exact={router.exact} component={router.render} key={index} />
            ))}
            <Redirect to={routers[0].path} />
          </Switch>
        </DialogView>
      </BrowserRouter>
    </div>
  );
};

export default App;
