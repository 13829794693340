/**
 * @description homepage video part
 * @author zqsun
 * @date 2024/7/2
 * @version 1.0
 */

import FlexCenter from 'react-flex-center';
import { Videos } from 'src/ts/img/video/video';
import styles from './VideoPart.module.scss';

const VideoPart = () => {
  return (
    <div id='VideoPart' className={styles.root}>
      <div className={styles.headerVideo}>
        <FlexCenter>
          <p className={styles.title1}>常識を超えろ</p>
        </FlexCenter>

        <p className={styles.title2}>GO MASSIVE</p>
      </div>

      <video autoPlay loop muted playsInline className={styles.videoBackground}>
        <source src={Videos.BackgroundVideo} type='video/mp4' />
      </video>
    </div>
  );
};

export default VideoPart;
