/**
 * @fileOverview Recoil state 主仓库
 * @author zqsun
 * @version 1.0
 */
import { useNewDialogStore } from 'new-verse-front-library/dist/ts/store/dialog-store';
import { atom, useRecoilState } from 'recoil';

/* member start */
const showMember1DialogState = atom<boolean>({
  key: 'showMember1DialogState',
  default: false,
});
const showMember2DialogState = atom<boolean>({
  key: 'showMember2DialogState',
  default: false,
});
const showMember3DialogState = atom<boolean>({
  key: 'showMember3DialogState',
  default: false,
});
const showMember4DialogState = atom<boolean>({
  key: 'showMember4DialogState',
  default: false,
});
const showMember5DialogState = atom<boolean>({
  key: 'showMember5DialogState',
  default: false,
});
const showMember6DialogState = atom<boolean>({
  key: 'showMember6DialogState',
  default: false,
});
const showMember7DialogState = atom<boolean>({
  key: 'showMember7DialogState',
  default: false,
});
const showMember8DialogState = atom<boolean>({
  key: 'showMember8DialogState',
  default: false,
});
/* member end */
export function useDialogStore() {
  // alert 弹窗 state
  const alertDialogStore = useNewDialogStore();
  const [showMember1Dialog, setShowMember1Dialog] = useRecoilState(showMember1DialogState);
  const [showMember2Dialog, setShowMember2Dialog] = useRecoilState(showMember2DialogState);
  const [showMember3Dialog, setShowMember3Dialog] = useRecoilState(showMember3DialogState);
  const [showMember4Dialog, setShowMember4Dialog] = useRecoilState(showMember4DialogState);
  const [showMember5Dialog, setShowMember5Dialog] = useRecoilState(showMember5DialogState);
  const [showMember6Dialog, setShowMember6Dialog] = useRecoilState(showMember6DialogState);
  const [showMember7Dialog, setShowMember7Dialog] = useRecoilState(showMember7DialogState);
  const [showMember8Dialog, setShowMember8Dialog] = useRecoilState(showMember8DialogState);
  return {
    ...alertDialogStore,
    showMember1Dialog,
    setShowMember1Dialog,
    showMember2Dialog,
    setShowMember2Dialog,
    showMember3Dialog,
    setShowMember3Dialog,
    showMember4Dialog,
    setShowMember4Dialog,
    showMember5Dialog,
    setShowMember5Dialog,
    showMember6Dialog,
    setShowMember6Dialog,
    showMember7Dialog,
    setShowMember7Dialog,
    showMember8Dialog,
    setShowMember8Dialog,
  };
}
