/**
 * @description contact page
 * @author zqsun
 * @date 2024/7/12
 * @version 1.0
 */

import { ChangeEvent, FormEvent, useState } from 'react';
import DebounceButton from 'react-debounce-button';
import FlexCenter from 'react-flex-center';
import { Link } from 'react-router-dom';
import { APP_URLS } from 'src/ts/router/url-router';
import { TailwindStyle } from 'src/tsx/style/TailwindStyle';
import Footer from 'src/tsx/views/components/atoms/Footer';
import Header from 'src/tsx/views/components/atoms/Header';
import ScrollToTop from 'src/tsx/views/components/atoms/ScrollToTop';
import styles from './ContactPage.module.scss';

const ContactPage = () => {
  /* state start*/
  const [name, setName] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [inquiryType, setInquiryType] = useState<string>('');
  const [inquiryContent, setInquiryContent] = useState<string>('');
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  /* state end*/

  /* function start*/
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type, checked } = e.target as HTMLInputElement;
    switch (name) {
      case 'name':
        setName(value);
        break;
      case 'company':
        setCompany(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'phone':
        setPhone(value);
        break;
      case 'inquiryType':
        setInquiryType(value);
        break;
      case 'inquiryContent':
        setInquiryContent(value);
        break;
      case 'privacyPolicy':
        setPrivacyPolicy(type === 'checkbox' ? checked : false);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // handle form submission logic here
  };
  /* function end*/

  return (
    <div id='ContactPage' className={styles.root}>
      <Header />
      <main
        id='HomepageMain'
        className={'homepageMain'}
        style={{
          background: 'rgba(242, 242, 242, 1)',
          paddingBottom: '0px',
        }}>
        <nav className={'nav'}>
          <ul>
            <Link to={APP_URLS.DEFAULT_URL}>TOP</Link>
            <span>{'  >  '}</span>
            <span>CONTACT</span>
          </ul>
        </nav>
        <div className={'mainRoot'}>
          <h2 className={styles.title}>CONTACT</h2>
        </div>

        <div
          style={{
            backgroundColor: 'white',
          }}>
          <div
            className={'mainRoot'}
            style={{
              paddingTop: '50px',
              paddingBottom: '50px',
            }}>
            <h3
              style={{
                fontSize: '14px',
                lineHeight: '22px',
              }}>
              ご相談・ご質問等がございましたら、
            </h3>
            <h3
              style={{
                fontSize: '14px',
                lineHeight: '22px',
              }}>
              フォームよりお気軽にお問い合わせください。
            </h3>

            {/* form 部分 start */}
            <div>
              <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.formGroup}>
                  <label htmlFor='name'>
                    お名前 <span className={styles.required}>必須</span>
                  </label>
                  <input
                    type='text'
                    id='name'
                    name='name'
                    value={name}
                    onChange={handleChange}
                    required
                    className={TailwindStyle.NORMAL_INPUT}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor='company'>会社名</label>
                  <input
                    type='text'
                    id='company'
                    name='company'
                    value={company}
                    onChange={handleChange}
                    className={TailwindStyle.NORMAL_INPUT}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor='email'>
                    メールアドレス <span className={styles.required}>必須</span>
                  </label>
                  <input
                    type='email'
                    id='email'
                    name='email'
                    value={email}
                    onChange={handleChange}
                    required
                    className={TailwindStyle.NORMAL_INPUT}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor='phone'>電話番号</label>
                  <input
                    type='tel'
                    id='phone'
                    name='phone'
                    value={phone}
                    onChange={handleChange}
                    className={TailwindStyle.NORMAL_INPUT}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor='inquiryType'>
                    お問い合わせ種別 <span className={styles.required}>必須</span>
                  </label>
                  <input
                    type='text'
                    id='inquiryType'
                    name='inquiryType'
                    value={inquiryType}
                    onChange={handleChange}
                    required
                    className={TailwindStyle.NORMAL_INPUT}
                  />
                </div>
                <div className={styles.formGroup}>
                  <label htmlFor='inquiryContent'>
                    お問い合わせ内容 <span className={styles.required}>必須</span>
                  </label>
                  <textarea
                    id='inquiryContent'
                    name='inquiryContent'
                    value={inquiryContent}
                    onChange={handleChange}
                    required
                    className={TailwindStyle.NORMAL_INPUT}
                  />
                </div>
                <FlexCenter className={styles.formGroup}>
                  <input
                    type='checkbox'
                    id='privacyPolicy'
                    name='privacyPolicy'
                    checked={privacyPolicy}
                    onChange={handleChange}
                    required
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '15px',
                    }}
                  />
                  <label
                    htmlFor='privacyPolicy'
                    style={{
                      marginBottom: 0,
                    }}>
                    <Link
                      to={APP_URLS.PRIVACY_POLICY}
                      style={{
                        color: 'blue',
                        textDecoration: 'underline',
                      }}>
                      プライバシーポリシー
                    </Link>
                    に同意する
                  </label>
                </FlexCenter>

                <FlexCenter>
                  <DebounceButton
                    type='submit'
                    className={styles.submitButton}
                    style={{
                      backgroundColor: privacyPolicy ? '#33C6C6' : '#F2F2F2',
                    }}>
                    送信
                  </DebounceButton>
                </FlexCenter>
              </form>
            </div>
            {/* form 部分 end */}
          </div>
        </div>
      </main>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default ContactPage;
