import { Images } from 'src/ts/img/image-index';
import { NewsImage } from 'src/ts/img/news/news-image';
import { NewsOneData } from 'src/ts/interface/news-interface';
import { APP_URLS } from 'src/ts/router/url-router';

export const NEWS_DATA: NewsOneData[] = [
  // {
  //   title: '令和6年能登半島地震への支援対応につきまして',
  //   picture: Images.BLACK_MASSIVE,
  //   time: '2024.3.12',
  //   link: '',
  // },
  // {
  //   title: 'プレシリーズAラウンドで約7.5億円の資金調達を実施致しました\n',
  //   picture: NewsImage.news1,
  //   time: '2023.10.18',
  //   link: '',
  // },
  {
    id: 1,
    title: 'エンジェルラウンド調達完了のご報告',
    picture: Images.BLACK_MASSIVE,
    time: '2021.2.28',
    link: APP_URLS.NEWS_DETAIL1,
  },
  {
    id: 2,
    title: 'シードラウンド調達完了のご報告',
    picture: Images.BLACK_MASSIVE,
    time: '2021.10.15',
    link: APP_URLS.NEWS_DETAIL2,
  },
  {
    id: 3,
    title: 'Google米国本社元副社長/日本法人名誉会長である村上憲郎が顧問に就任',
    picture: Images.BLACK_MASSIVE,
    time: '2021.10.23',
    link: APP_URLS.NEWS_DETAIL3,
  },
  {
    id: 4,
    title: 'ホバーバイク「XTURISMO」のBGMに弊社が楽曲提供させて頂きました',
    picture: Images.BLACK_MASSIVE,
    time: '2021.10.26',
    link: APP_URLS.NEWS_DETAIL4,
  },
  {
    id: 5,
    title: 'シードラウンド調達額が累計2.5億円達成のご報告',
    picture: Images.BLACK_MASSIVE,
    time: '2022.3.10',
    link: APP_URLS.NEWS_DETAIL5,
  },
  {
    id: 6,
    title: '「SQUARE21」の表紙を飾らせて頂きました',
    picture: NewsImage.news6,
    time: '2023.5.17',
    link: APP_URLS.NEWS_DETAIL6,
  },
  {
    id: 7,
    title: '文化放送「おとなりさん」にゲスト出演',
    picture: Images.BLACK_MASSIVE,
    time: '2023.5.17',
    link: APP_URLS.NEWS_DETAIL7,
  },
  {
    id: 8,
    title: '経済界の会合にて登壇',
    picture: NewsImage.news8,
    time: '2023.5.17',
    link: APP_URLS.NEWS_DETAIL8,
  },
  {
    id: 9,
    title: '株式会社エフェクチュアルとの業務提携に関するお知らせ',
    picture: Images.BLACK_MASSIVE,
    time: '2023.5.23',
    link: APP_URLS.NEWS_DETAIL9,
  },
  {
    id: 10,
    title: '資本金及び準備金の額の減少公告',
    picture: Images.BLACK_MASSIVE,
    time: '2023.6.26',
    link: APP_URLS.NEWS_DETAIL10,
  },
];
