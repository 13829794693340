/**
 * @description homepage service part
 * @author zqsun
 * @date 2024/7/2
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import DebounceButton from 'react-debounce-button';
import FlexCenter from 'react-flex-center';
import { Link } from 'react-router-dom';
import { ServiceImage } from 'src/ts/img/service/service-image';
import { Videos } from 'src/ts/img/video/video';
import { APP_URLS } from 'src/ts/router/url-router';
import styles from './ServicePart.module.scss';

const ServicePart = () => {
  return (
    <div id='ServicePart' className={styles.root}>
      <div className={'mainRoot'}>
        <h1 className={styles.title}>SERVICES</h1>
        <p
          className={'greenTitle'}
          style={{
            color: 'white',
          }}>
          Gamification
        </p>
        <p
          className={'greenTitle'}
          style={{
            color: 'white',
          }}>
          Data Driven
        </p>
      </div>
      <div>
        <Grid container>
          <Grid item xs={12} md={6} className={styles.serviceOuterGrid} data-flexJustifyContent={'flex-end'}>
            <div
              style={{
                maxWidth: '80%',
              }}>
              <p className={styles.title1}>MASSIVE MAP</p>
              <p className={styles.title2}>生成AIがあなたの旅行を最適化</p>
              <p className={styles.serviceText}>
                自分のデータ/趣味趣向等を入力するとAIがジェネレートし自分に合ったおすすめの観光スポットとルートを地図上に表示しユーザーの旅行を最適化します。
              </p>

              <FlexCenter>
                <Link to={APP_URLS.MASSIVE_MAP}>
                  <DebounceButton
                    className={'moreButton'}
                    style={{
                      color: '#242424',
                      backgroundColor: 'white',
                      marginTop: 20,
                      marginBottom: 20,
                    }}>
                    MORE
                  </DebounceButton>
                </Link>
              </FlexCenter>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <FlexCenter>
              <img src={ServiceImage.SERVICE_IMAGE1} alt='service' className={styles.serviceImage} />
            </FlexCenter>
          </Grid>
        </Grid>
        <Grid container paddingTop={2}>
          <Grid item xs={12} md={6} className={'onlyShowOnPC'}>
            <FlexCenter>
              <img src={ServiceImage.SERVICE_IMAGE2} alt='service' className={styles.serviceImage} />
            </FlexCenter>
          </Grid>
          <Grid item xs={12} md={6} className={styles.serviceOuterGrid} data-flexJustifyContent={'flex-start'}>
            <div
              style={{
                maxWidth: '80%',
              }}>
              <p className={styles.title1}>OPEN PORTAL EXPO</p>
              <p className={styles.title2}>Gamification × Data</p>
              <p className={styles.serviceText}>
                エキスポに来場したユーザーが会場内で楽しめる体験型ゲームアプリ。提供ブースを出展する企業様にはデータを提供可能となる世界初のエンタメエキスポです。
              </p>
              <FlexCenter>
                <Link to={APP_URLS.OPEN_EXPO}>
                  <DebounceButton
                    className={'moreButton'}
                    style={{
                      color: '#242424',
                      backgroundColor: 'white',
                      marginTop: 20,
                      marginBottom: 20,
                    }}>
                    MORE
                  </DebounceButton>
                </Link>
              </FlexCenter>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={'onlyShowOnMobile'}>
            <FlexCenter>
              <img src={ServiceImage.SERVICE_IMAGE2} alt='service' className={styles.serviceImage} />
            </FlexCenter>
          </Grid>
        </Grid>
        <Grid container paddingTop={2}>
          <Grid item xs={12} md={6} className={styles.serviceOuterGrid} data-flexJustifyContent={'flex-end'}>
            <div
              style={{
                maxWidth: '80%',
                margin: 'auto',
              }}>
              <p className={styles.title1}>広告事業</p>
              <p className={styles.title2}>アジア最大のPR会社「BlueFocus」とAdTech戦略パートナーを締結</p>
              <p className={styles.serviceText}>
                他社と比較して、我々の広告運用手数料は平均して5％から10％も安価に提供することが可能です。さらに、万が一アカウントが停止された場合でも、無制限に新しいアカウントを発行できるという圧倒的な強みを持っています。この柔軟性とコスト効率の高さが、他に類を見ない我々のサービスの魅力です。
              </p>
              <FlexCenter>
                <DebounceButton
                  className={'moreButton'}
                  style={{
                    color: '#242424',
                    backgroundColor: 'white',
                    marginTop: 20,
                    marginBottom: 20,
                  }}>
                  MORE
                </DebounceButton>
              </FlexCenter>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <FlexCenter>
              <img src={ServiceImage.SERVICE_IMAGE3} alt='service' className={styles.serviceImage} />
            </FlexCenter>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={6} className={'onlyShowOnPC'}>
            <FlexCenter>
              <img src={ServiceImage.SERVICE_IMAGE4} alt='service' className={styles.serviceImage} />
            </FlexCenter>
          </Grid>
          <Grid item xs={12} md={6} className={styles.serviceOuterGrid} data-flexJustifyContent={'flex-start'}>
            <div
              style={{
                maxWidth: '80%',
                margin: 'auto',
              }}>
              <p className={styles.title1}>アート事業</p>
              <p className={styles.title2}>アートラウンジの運営</p>
              <p className={styles.title2}>アートイベントの企画制作</p>
              <p className={styles.serviceText}>
                会員及び招待者限定のアートラウンジを運営。アート展示や音楽鑑賞、飲食が可能な特別な空間を提供します。また、アーティストとともに企業やブランドと提携したアートイベントを企画制作しています。
              </p>
              <FlexCenter>
                <Link to={APP_URLS.ART}>
                  <DebounceButton
                    className={'moreButton'}
                    style={{
                      color: '#242424',
                      backgroundColor: 'white',
                      marginTop: 20,
                      marginBottom: 20,
                    }}>
                    MORE
                  </DebounceButton>
                </Link>
              </FlexCenter>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={'onlyShowOnMobile'}>
            <FlexCenter>
              <img src={ServiceImage.SERVICE_IMAGE4} alt='service' className={styles.serviceImage} />
            </FlexCenter>
          </Grid>
        </Grid>
      </div>
      <video autoPlay loop muted playsInline className={styles.videoBackground}>
        <source src={Videos.BackgroundVideo} type='video/mp4' />
      </video>
    </div>
  );
};

export default ServicePart;
