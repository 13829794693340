/**
 * @description footer
 * @author zqsun
 * @date 2024/7/3
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import FlexCenter from 'react-flex-center';
import { Link } from 'react-router-dom';
import { Images } from 'src/ts/img/image-index';
import { APP_URLS } from 'src/ts/router/url-router';
import styles from './Footer.module.scss';

const Footer = () => {
  return (
    <div id='Footer' className={styles.root}>
      <Grid
        container={true}
        style={{
          alignItems: 'center',
        }}>
        <Grid item={true} xs={12} md={6} className={'h-full'}>
          <FlexCenter className={'h-full'}>
            <img src={Images.MASSIVE_WHITE_LOGO} alt={'Massive'} className={styles.footerLogo} />
          </FlexCenter>
        </Grid>
        <Grid
          item={true}
          xs={12}
          md={6}
          className={'h-full'}
          style={{
            alignItems: 'center',
          }}>
          <Grid
            container={true}
            className={'h-full'}
            style={{
              alignItems: 'center',
            }}>
            <Grid item={true} xs={12} md={4}>
              <FlexCenter
                style={{
                  paddingBottom: '10px',
                  paddingTop: '10px',
                }}
                className={'h-full'}>
                <Link to={APP_URLS.PRIVACY_POLICY} className={styles.privacy}>
                  プライバシーポリシー
                </Link>
              </FlexCenter>
            </Grid>
            <Grid item={true} xs={12} md={8} className={'h-full'}>
              <FlexCenter
                style={{
                  paddingBottom: '10px',
                  paddingTop: '10px',
                  flexDirection: 'column',
                }}
                className={'h-full'}>
                <p className={styles.privacy}>Copyright © Super Massive Global Co., Ltd.</p>
                <p className={styles.privacy}> All Rights Reserved.</p>
              </FlexCenter>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
