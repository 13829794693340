/**
 * @description 标题小菱形
 * @author zqsun
 * @date 2024/7/8
 * @version 1.0
 */
import { useId } from 'react';

const TitlePath = () => {
  /* state start*/
  const componentId: string = useId();
  /* state end*/
  return (
    <svg
      id={'TitlePath' + componentId}
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='14.99407958984375'
      height='17.44482421875'
      viewBox='0 0 14.99407958984375 17.44482421875'
      fill='none'
      style={{
        display: 'inline-flex',
        marginRight: '10px',
      }}>
      <path d='M14.9941 0L5.49182 0L0 17.4448L9.50238 17.4448L14.9941 0Z' fill='#33C6C6'></path>
    </svg>
  );
};

export default TitlePath;
