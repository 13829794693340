/**
 * @description art mirage
 * @author zqsun
 * @date 2024/7/10
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import DebounceButton from 'react-debounce-button';
import FlexCenter from 'react-flex-center';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import { ArtImage } from 'src/ts/img/art/art-image';
import { APP_URLS } from 'src/ts/router/url-router';
import Footer from 'src/tsx/views/components/atoms/Footer';
import Header from 'src/tsx/views/components/atoms/Header';
import NewsOne from 'src/tsx/views/components/modules/newsPart/NewsOne';
import styles from './ArtMirage.module.scss';

const ArtMirage = () => {
  /* constant start*/
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '10%', // 调整显示左右两边5%的幻灯片
    nextArrow: <IoIosArrowForward className='arrow next' color={'white'} size={48} />,
    prevArrow: <IoIosArrowBack className='arrow prev' color={'white'} />,
  };
  /* constant end*/
  return (
    <div id='ArtMirage' className={styles.root}>
      <Header from={'Art'} />
      <main
        className={'homepageMain'}
        style={{
          background: '#363636',
          paddingBottom: 0,
        }}>
        <nav
          className={'nav'}
          style={{
            background: '#363636',
          }}>
          <ul>
            <Link to={APP_URLS.DEFAULT_URL}>TOP</Link>
            <span>{'  >  '}</span>
            <Link to={APP_URLS.ART}>イベント企画 アートラウンジ運営</Link>
            <span>{'  >  '}</span>
            <span>イベント</span>
          </ul>
        </nav>

        {/* on view part start*/}
        <section
          style={{
            background: '#363636',
          }}>
          <div className={'mainRoot'}>
            <h2 className={styles.title}>On View</h2>
            <Grid container className={styles.onViewMain}>
              <Grid item xs={12} md={5}>
                {/* <img src={ArtImage.ART_IMAGE3} alt={'Art'} className={styles.artImage3} />*/}
                <Slider {...settings}>
                  <div>
                    <img src={ArtImage.ART_IMAGE3} alt={'Art'} className={styles.artImage3} />
                  </div>
                  <div>
                    <img src={ArtImage.ART_IMAGE3} alt={'Art'} className={styles.artImage3} />
                  </div>
                  <div>
                    <img src={ArtImage.ART_IMAGE3} alt={'Art'} className={styles.artImage3} />
                  </div>
                </Slider>
              </Grid>
              <Grid item xs={12} md={7}>
                <div
                  style={{
                    width: '80%',
                    margin: 'auto',
                  }}>
                  <p
                    style={{
                      fontSize: '20px',
                    }}>
                    Mirage
                  </p>
                  <h2 className={styles.artEventPlanningSubTitle}>
                    名和晃平とダミアン・ジャレによる コラボレーション三部作に続く新たな展開
                  </h2>

                  <p
                    className={styles.artEventPlanningText}
                    style={{
                      marginTop: 20,
                    }}>
                    二人はこれまで10
                    年にわたってコラボレーションを続け、三部作のパフォーマンスを発表してきた̶̶死と再生がテーマの《VESSEL》、霧を用いた《Mist》、宇宙と人間の進化を描く《Planet
                    [wanderer]》 《Mirage》はこれらの続編として、その文脈を共有しつつ、さまざまな新たな挑戦を行う。
                    ダンサーの肌にはこれまでになかった鮮やかな色彩が加えられ、イマーシブシアター〈THEATER
                    010〉のインタラクティブな環境を活かしたダイナミックな舞台が構成される。
                    また、博多の山笠を念頭に置いた、新たなトーテミズムの表現も見どころとなる。
                  </p>
                  <p
                    className={styles.artEventPlanningText}
                    style={{
                      marginTop: 20,
                    }}>
                    Damien Jalet | Kohei Nawa
                    <br />
                    会場:Theater010 公演時間:約90分(間奏あり)
                    <br />
                    期間：2024年9月-10月
                  </p>

                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <FlexCenter>
                        <a
                          href='https://eplus.jp/sf/detail/4143000001-P0030001'
                          target='_blank'
                          rel='noopener noreferrer'>
                          <DebounceButton
                            className={styles.moreButton}
                            style={{
                              background: 'rgb(51, 198, 198)',
                            }}>
                            特設ページへ
                          </DebounceButton>
                        </a>
                      </FlexCenter>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FlexCenter>
                        <Link to={APP_URLS.ART}>
                          <DebounceButton className={styles.moreButton}>Back</DebounceButton>
                        </Link>
                      </FlexCenter>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
        {/* on view part end*/}

        {/* ARCHIVE part start*/}
        <section
          style={{
            background: '#363636',
            paddingBottom: 50,
          }}>
          <div className={'mainRoot'}>
            <h2 className={styles.title}>ARCHIVE</h2>

            <Grid container>
              <Grid item xs={12} md={3}>
                <NewsOne
                  picture={ArtImage.ART_IMAGE3}
                  title={'名和晃平とダミアン・ジャレによるコラボレーション三部作に続く新たな展開\n'}
                  time={''}
                  link={''}
                  from={'Art'}
                />
              </Grid>
            </Grid>
          </div>
        </section>
        {/* ARCHIVE part end*/}
      </main>
      <Footer />
    </div>
  );
};

export default ArtMirage;
