/**
 * @description homepage news one
 * @author zqsun
 * @date 2024/7/3
 * @version 1.0
 */

import { Paper } from '@mui/material';
import { useId } from 'react';
import FlexCenter from 'react-flex-center';
import { NewsOneData } from 'src/ts/interface/news-interface';
import styles from './NewsOne.module.scss';

interface NewsOneProps extends NewsOneData {
  from?: 'News' | 'Art';
}
const NewsOne = ({ picture, title, time, link, from }: NewsOneProps) => {
  /* constant start*/
  const componentId: string = useId();
  /* constant end*/
  return (
    <div id={'NewsOne' + componentId} className={styles.root}>
      <Paper className={styles.newsPaper} elevation={6} data-from={from}>
        <div>
          <img src={picture} alt={title} className={styles.image} />
        </div>
        {time && <div className={styles.time}>{time}</div>}
        <div className={styles.title}>{title}</div>
        <FlexCenter
          style={{
            justifyContent: 'flex-end',
          }}>
          <a href={link} className={styles.moreLink}>
            More &gt;
          </a>
        </FlexCenter>
      </Paper>
    </div>
  );
};

export default NewsOne;
