/**
 * @description news detail 2
 * @author zqsun
 * @date 2024/7/22
 * @version 1.0
 */

import FlexCenter from 'react-flex-center';
import { Link } from 'react-router-dom';
import { NewsImage } from 'src/ts/img/news/news-image';
import { APP_URLS } from 'src/ts/router/url-router';
import styles from './NewsDetail.module.scss';

const NewsDetail2 = () => {
  return (
    <div id='NewsDetail2' className={styles.root}>
      <div className={'mainRoot'}>
        <h2 className={styles.title}>News</h2>
      </div>
      <div
        style={{
          backgroundColor: 'white',
        }}>
        <div
          className={'mainRoot'}
          style={{
            paddingTop: '50px',
            paddingBottom: '50px',
          }}>
          <p className={'font-bold mb-1.5'}>2021.10.15</p>
          <h1 className={styles.newsTitle}>シードラウンド調達完了のご報告</h1>

          <article className={styles.newsText}>
            Super Massive Global 株式会社(東京都港区、CEO
            内藤慎人)は、エンジェルラウンドにおいて総額約1.1億円の資金調達を完了いたしましたことを報告いたします。調達した資金は、「バーチャルと現実の境界線を無くす」というミッション実現に向け開発資金に投資いたします。具体的には、弊社デジタルツインプラットフォームの開発、並びにパートナーとのチームワークを高めるための環境整備を進めていきます。
          </article>

          <FlexCenter style={{ justifyContent: 'space-evenly' }}>
            <Link to={APP_URLS.NEWS_DETAIL1}>
              <FlexCenter>
                <img src={NewsImage.newsPrev} alt='news' />
                <p
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}>
                  前ページ
                </p>
              </FlexCenter>
            </Link>
            <div>
              <Link to={APP_URLS.NEWS}>記事一覧</Link>
            </div>
            <Link to={APP_URLS.NEWS_DETAIL3}>
              <FlexCenter>
                <p
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}>
                  次ページ
                </p>
                <img src={NewsImage.newsNext} alt='news' />
              </FlexCenter>
            </Link>
          </FlexCenter>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail2;
