/**
 * @description 乾 牧夫 dialog
 * @author zqsun
 * @date 2024/7/4
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import FlexCenter from 'react-flex-center';
import { TeamImage } from 'src/ts/img/teams/team-image';
import { useDialogStore } from 'src/ts/stores/dialog-store';
import DialogCloseIcon from 'src/tsx/views/components/atoms/DialogCloseIcon';
import styles from './Staff.module.scss';

const Staff4 = () => {
  /* state start*/
  const { setShowMember4Dialog } = useDialogStore();
  /* state end*/

  /* function start*/
  const closeDialog = () => {
    setShowMember4Dialog(false);
  };
  /* function end*/
  return (
    <div id='Staff4' className={styles.root}>
      <FlexCenter style={{ justifyContent: 'flex-end' }}>
        <DialogCloseIcon onClick={closeDialog} />
      </FlexCenter>

      <Grid
        container
        spacing={4}
        className={'h-full'}
        style={{
          alignItems: 'center',
          marginTop: '-50px',
        }}>
        {/* text part start*/}
        <Grid item xs={12} md={6} className={'h-full'}>
          <FlexCenter className={'h-full'}>
            <div className={'h-full'}>
              <article className={styles.article0}>
                23年間、東京及び香港で証券アナリスト・投資銀行家として活躍。専門分野は通信/TMT。Institutional
                Investor誌アナリストランキング登場回数16回は歴代17位。
                ソロモン・ブラザーズ・UBSの両社で、主にNTT民営化、NTTドコモ上場,
                ボーダーフォンのソフトバンク売却等に携わる。2013年よりスタートアップ業界へ転身、CFOとしてPaidy
                含む複数企業の資金調達や上場準備を経験
              </article>
              <div
                style={{
                  marginTop: '30px',
                }}>
                <span className={styles.position}>CFO</span>
                <span
                  style={{
                    fontSize: '28px',
                    lineHeight: '42px',
                    marginLeft: '10px',
                  }}>
                  乾 牧夫
                </span>
              </div>
            </div>
          </FlexCenter>
        </Grid>
        {/* text part end*/}

        {/* photo part start*/}
        <Grid item xs={12} md={6}>
          <FlexCenter>
            <img src={TeamImage.staff4} alt={''} className={styles.staffImage0} />
          </FlexCenter>
        </Grid>
        {/* photo part end*/}
      </Grid>
    </div>
  );
};

export default Staff4;
