/**
 * @description homepage
 * @author zqsun
 * @date 2024/07/02
 * @version 1.0
 */

import Footer from 'src/tsx/views/components/atoms/Footer';
import Header from 'src/tsx/views/components/atoms/Header';
import AboutUsPart from 'src/tsx/views/pages/homepage/AboutUsPart';
import MassiveWays from 'src/tsx/views/pages/homepage/MassiveWays';
import NewsPart from 'src/tsx/views/pages/homepage/NewsPart';
import ServicePart from 'src/tsx/views/pages/homepage/ServicePart';
import VideoPart from 'src/tsx/views/pages/homepage/VideoPart';
import styles from './HomePage.module.scss';

const Homepage = () => {
  return (
    <div id='Homepage' className={styles.root}>
      <Header />
      <main id='HomepageMain' className={'homepageMain'}>
        <VideoPart />
        <MassiveWays />
        <ServicePart />
        <NewsPart />
        <AboutUsPart showMore={true} />
      </main>
      <Footer />
    </div>
  );
};

export default Homepage;
