export class APP_URLS {
  static readonly DEFAULT_URL: string = '/';
  static readonly ABOUT_US: string = '/about-us';
  static readonly SERVICE: string = '/service';
  static readonly MEMBER: string = '/member';
  // massive map
  static readonly MASSIVE_MAP: string = '/massive-map';
  // open expo
  static readonly OPEN_EXPO: string = '/open-expo';
  // advertising
  static readonly ADVERTISING: string = '/advertising';
  // art
  static readonly ART: string = '/art';
  // art mirage
  static readonly ART_MIRAGE: string = '/art/mirage';
  // contact
  static readonly CONTACT: string = '/contact';
  // privacy policy
  static readonly PRIVACY_POLICY: string = '/privacy-policy';
  // news page
  static readonly NEWS: string = '/news';
  // news detail1
  static readonly NEWS_DETAIL1: string = '/news/1';
  static readonly NEWS_DETAIL2: string = '/news/2';
  static readonly NEWS_DETAIL3: string = '/news/3';
  static readonly NEWS_DETAIL4: string = '/news/4';
  static readonly NEWS_DETAIL5: string = '/news/5';
  static readonly NEWS_DETAIL6: string = '/news/6';
  static readonly NEWS_DETAIL7: string = '/news/7';
  static readonly NEWS_DETAIL8: string = '/news/8';
  static readonly NEWS_DETAIL9: string = '/news/9';
  static readonly NEWS_DETAIL10: string = '/news/10';
}
