/**
 * @description Massive Logo
 * @author zqsun
 * @date 2024/7/2
 * @version 1.0
 */

const MassiveLogo = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width='177.43505859375'
      height='57.3447265625'
      viewBox='0 0 177.43505859375 57.3447265625'
      fill='none'>
      <path
        d='M33.3257 12.7148L28.3228 12.7148L20.1569 29.046C19.5517 30.2565 18.7833 31.8127 18.0316 33.4291C17.435 32.1929 16.8137 30.9536 16.2334 29.7954L8.14767 13.668L7.66778 12.7148L2.67023 12.7148L0 44.8575L6.75382 44.8575L8.05481 29.396C8.06578 29.2741 8.07699 29.15 8.0882 29.0243C8.28757 29.4298 8.47475 29.8044 8.65096 30.1483L15.6619 44.0808L16.1403 45.0371L19.6763 45.0371L27.0923 30.1096C27.2873 29.7186 27.4971 29.2997 27.715 28.8612C27.7301 29.0501 27.7442 29.2241 27.7577 29.3813L28.9261 43.2672L29.0555 44.8575L36.0413 44.8575L33.4552 14.3049L33.3257 12.7148Z'
        fill='#FFFFFF'></path>
      <rect
        x='117.28759765625'
        y='12.71484375'
        width='7.148298263549805'
        height='32.142574310302734'
        fill='#FFFFFF'></rect>
      <path
        d='M164.308 38.2036L164.308 32.0456L173.884 32.0456L173.884 25.3918L164.308 25.3918L164.308 19.3686L175.817 19.3686L175.817 12.7148L157.16 12.7148L157.16 44.8574L177.435 44.8574L177.435 38.2036L164.308 38.2036Z'
        fill='#FFFFFF'></path>
      <path
        d='M140.895 32.7686L134.236 12.7148L126.894 12.7148L139.039 44.8574L142.662 44.8574L154.808 12.7148L147.556 12.7148L140.895 32.7686Z'
        fill='#FFFFFF'></path>
      <path d='M147.391 0L140.614 0L136.697 12.4422L143.474 12.4422L147.391 0Z' fill='#FFFFFF'></path>
      <path
        d='M51.603 24.7724L58.2621 44.8262L65.6047 44.8262L53.4595 12.6836L49.8361 12.6836L37.6904 44.8262L44.9421 44.8262L51.603 24.7724Z'
        fill='#FFFFFF'></path>
      <path d='M45.1069 57.3445L51.8844 57.3445L55.8013 44.9023L49.0239 44.9023L45.1069 57.3445Z' fill='#FFFFFF'></path>
      <path
        d='M106.903 25.5583L100.112 25.5583C99.2812 25.5583 98.6743 25.3428 98.232 24.8754C97.7908 24.4523 97.5768 23.8125 97.5768 22.919C97.5768 21.6912 97.8286 20.813 98.3195 20.3161C98.7874 19.8535 99.6343 19.6188 100.838 19.6188L114.238 19.6188L114.264 12.7148L100.072 12.7148C96.9595 12.7148 94.5949 13.5484 93.0185 15.2186C91.5725 16.9181 90.8389 19.4801 90.8389 22.8337C90.8389 26.0275 91.5644 28.4379 92.9924 29.9955C94.4118 31.5614 96.6509 32.3555 99.6487 32.3555L105.714 32.3555C106.824 32.3555 107.628 32.5622 108.013 32.9282C108.463 33.4057 108.692 34.1724 108.692 35.2078C108.692 36.2243 108.462 36.9506 108.008 37.3672C107.545 37.7925 106.64 38.0177 105.391 38.0177L91.8264 38.0177L91.8264 44.8575L106.177 44.8575C109.306 44.8575 111.653 44.0288 113.144 42.404C114.648 40.8268 115.41 38.3338 115.41 34.9943C115.41 31.7067 114.771 29.3711 113.458 27.8546C112.138 26.3306 109.933 25.5583 106.903 25.5583Z'
        fill='#FFFFFF'></path>
      <path
        d='M82.2501 25.5583L75.4597 25.5583C74.6289 25.5583 74.0218 25.3428 73.5794 24.8754C73.1385 24.4523 72.9245 23.8125 72.9245 22.919C72.9245 21.6912 73.176 20.813 73.6669 20.3161C74.1348 19.8535 74.9818 19.6188 76.185 19.6188L89.5862 19.6188L89.6117 12.7148L75.4193 12.7148C72.3071 12.7148 69.9423 13.5484 68.3659 15.2186C66.9199 16.9181 66.1865 19.4801 66.1865 22.8337C66.1865 26.0275 66.9119 28.4379 68.3398 29.9955C69.7593 31.5614 71.9984 32.3555 74.9962 32.3555L81.0612 32.3555C82.1721 32.3555 82.9759 32.5622 83.361 32.9282C83.8109 33.4057 84.0392 34.1724 84.0392 35.2078C84.0392 36.2243 83.8094 36.9506 83.3556 37.3672C82.892 37.7925 81.9871 38.0177 80.7387 38.0177L67.1739 38.0177L67.1739 44.8575L81.5248 44.8575C84.6539 44.8575 87.0007 44.0288 88.4918 42.404C89.9953 40.8268 90.7575 38.3338 90.7575 34.9943C90.7575 31.7067 90.1191 29.3711 88.806 27.8546C87.4855 26.3306 85.28 25.5583 82.2501 25.5583Z'
        fill='#FFFFFF'></path>
    </svg>
  );
};

export default MassiveLogo;
