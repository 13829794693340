/**
 * @description news page
 * @author zqsun
 * @date 2024/7/22
 * @version 1.0
 */

import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { newsRouters } from 'src/ts/router/news-router';
import { APP_URLS } from 'src/ts/router/url-router';
import Footer from 'src/tsx/views/components/atoms/Footer';
import Header from 'src/tsx/views/components/atoms/Header';
import ScrollToTop from 'src/tsx/views/components/atoms/ScrollToTop';
import styles from './NewsPage.module.scss';

const NewsPage = () => {
  return (
    <div id='NewsPage' className={styles.root}>
      <Header />
      <main
        id='HomepageMain'
        className={'homepageMain'}
        style={{
          background: 'rgba(242, 242, 242, 1)',
          paddingBottom: '0px',
        }}>
        <nav className={'nav'}>
          <ul>
            <Link to={APP_URLS.DEFAULT_URL}>TOP</Link>
            <span>{'  >  '}</span>
            <span>NEWS</span>
          </ul>
        </nav>
        <Switch>
          {newsRouters.map((router, index) => (
            <Route path={router.path} exact={router.exact} component={router.render} key={index} />
          ))}
          <Redirect to={newsRouters[0].path} />
        </Switch>
      </main>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default NewsPage;
