/**
 * @description news detail 8
 * @author zqsun
 * @date 2024/7/22
 * @version 1.0
 */

import FlexCenter from 'react-flex-center';
import { Link } from 'react-router-dom';
import { NewsImage } from 'src/ts/img/news/news-image';
import { APP_URLS } from 'src/ts/router/url-router';
import styles from './NewsDetail.module.scss';

const NewsDetail8 = () => {
  return (
    <div id='NewsDetail8' className={styles.root}>
      <div className={'mainRoot'}>
        <h2 className={styles.title}>News</h2>
      </div>
      <div
        style={{
          backgroundColor: 'white',
        }}>
        <div
          className={'mainRoot'}
          style={{
            paddingTop: '50px',
            paddingBottom: '50px',
          }}>
          <p className={'font-bold mb-1.5'}>2023.5.17</p>
          <h1 className={styles.newsTitle}>経済界の会合にて登壇</h1>

          <article className={styles.newsText}>
            <FlexCenter style={{ marginBottom: '20px' }}>
              <img src={NewsImage.news8} alt='' />
            </FlexCenter>
            2023年5月15日（月）に経済界主催の会合にて、小泉進次郎様と弊社代表の内藤が対談させて頂きました。
          </article>

          <FlexCenter style={{ justifyContent: 'space-evenly' }}>
            <Link to={APP_URLS.NEWS_DETAIL7}>
              <FlexCenter>
                <img src={NewsImage.newsPrev} alt='news' />
                <p
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}>
                  前ページ
                </p>
              </FlexCenter>
            </Link>
            <div>
              <Link to={APP_URLS.NEWS}>記事一覧</Link>
            </div>
            <Link to={APP_URLS.NEWS_DETAIL9}>
              <FlexCenter>
                <p
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}>
                  次ページ
                </p>
                <img src={NewsImage.newsNext} alt='news' />
              </FlexCenter>
            </Link>
          </FlexCenter>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail8;
