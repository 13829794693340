/**
 * @description homepage news part
 * @author zqsun
 * @date 2024/7/3
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import { NEWS_DATA } from 'src/ts/constant/news-constant';
import NewsOne from 'src/tsx/views/components/modules/newsPart/NewsOne';
import styles from './NewsPart.module.scss';

const NewsPart = () => {
  return (
    <div id='NewsPart' className={styles.root}>
      <div className={'mainRoot'}>
        <h1 className={styles.title}>News</h1>

        <Grid container>
          {NEWS_DATA.reverse().map((item, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <NewsOne picture={item.picture} title={item.title} time={item.time} text={item.text} link={item.link} />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default NewsPart;
