/**
 * @description 柴田 秀樹 dialog
 * @author zqsun
 * @date 2024/7/4
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import FlexCenter from 'react-flex-center';
import { TeamImage } from 'src/ts/img/teams/team-image';
import { useDialogStore } from 'src/ts/stores/dialog-store';
import DialogCloseIcon from 'src/tsx/views/components/atoms/DialogCloseIcon';
import styles from './Staff.module.scss';

const Staff3 = () => {
  /* state start*/
  const { setShowMember3Dialog } = useDialogStore();
  /* state end*/

  /* function start*/
  const closeDialog = () => {
    setShowMember3Dialog(false);
  };
  /* function end*/
  return (
    <div id='Staff3' className={styles.root}>
      <FlexCenter style={{ justifyContent: 'flex-end' }}>
        <DialogCloseIcon onClick={closeDialog} />
      </FlexCenter>

      <Grid
        container
        spacing={4}
        className={'h-full'}
        style={{
          alignItems: 'center',
          marginTop: '-50px',
        }}>
        {/* text part start*/}
        <Grid item xs={12} md={6} className={'h-full'}>
          <FlexCenter className={'h-full'}>
            <div className={'h-full'}>
              <article className={styles.article0}>
                外資系コンサルティングファームにて、米国・日本の銀行、証券会社などの金融機関における事業戦略・新規事業プロジェクトに従事。帰国後、日本にて経営・財務コンサルティング会社「株式会社
                FORA」設立。その後、2014年「株式会社 Kort
                valuta」設立。その他、バイオベンチャー取締役、金融機関アドバイザリーを務める。仮想通貨Rippleを開発したコアエンジニアの一人。
              </article>
              <div
                style={{
                  marginTop: '30px',
                }}>
                <span className={styles.position}>CIO</span>
                <span
                  style={{
                    fontSize: '28px',
                    lineHeight: '42px',
                    marginLeft: '10px',
                  }}>
                  柴田 秀樹
                </span>
              </div>
            </div>
          </FlexCenter>
        </Grid>
        {/* text part end*/}

        {/* photo part start*/}
        <Grid item xs={12} md={6}>
          <FlexCenter>
            <img src={TeamImage.staff3} alt={''} className={styles.staffImage0} />
          </FlexCenter>
        </Grid>
        {/* photo part end*/}
      </Grid>
    </div>
  );
};

export default Staff3;
