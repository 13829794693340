/**
 * @description homepage our team part
 * @author zqsun
 * @date 2024/7/3
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import FlexCenter from 'react-flex-center';
import { TeamImage } from 'src/ts/img/teams/team-image';
import { useDialogStore } from 'src/ts/stores/dialog-store';
import styles from './OurTeamPart.module.scss';

const OurTeamPart = () => {
  /* state start*/
  const {
    setShowMember1Dialog,
    setShowMember2Dialog,
    setShowMember3Dialog,
    setShowMember4Dialog,
    setShowMember5Dialog,
    setShowMember6Dialog,
    setShowMember7Dialog,
    setShowMember8Dialog,
  } = useDialogStore();
  /* state end*/
  return (
    <div id='OurTeamPart' className={styles.root}>
      <div className={'mainRoot'}>
        <h1 className={styles.title}>Our Team</h1>
        {/* 内藤 part start*/}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <article className={styles.article0}>
              <p>
                <big>
                  <b>「世界に巨大なインパクトを与える企業を創りたい」</b>
                </big>
              </p>
              <p>
                <big>
                  <b>「どんな企業や国とも手を取り合い、共に巨大になる」</b>
                </big>
              </p>
              この二つが私たちのVisionです。そこでゲーム性のある観光/EXPOアプリを展開し、IPとゲーミフィケーションによってユーザーに楽しみを提供しながら移動や決済、その他生活に直結するデータを取得/分析し、様々な産業の発展に活用可能なデータを提供することで街や国の発展に貢献していくのが我々の目標です。
              バスケ少年だった私が、今ここに立てているのは、既成概念に囚われず大胆に行動し、前に進んできたからだと自負しています。
              新しい技術がすごいスピードで出てきている今、これまで実現できなかったからといって、この先もできないとは限らない世界になってきていますし、それを実現し続けるのが弊社がやるべきミッションと考えています。
            </article>
            <div
              style={{
                marginTop: '30px',
              }}>
              <span
                style={{
                  fontSize: '15px',
                  lineHeight: '28px',
                }}>
                代表取締役 CEO
              </span>
              <span
                style={{
                  fontSize: '28px',
                  lineHeight: '42px',
                  marginLeft: '10px',
                }}>
                内藤 慎人
              </span>
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <FlexCenter>
              <img src={TeamImage.staff0} alt={''} className={styles.staffImage0} />
            </FlexCenter>
          </Grid>
        </Grid>
        {/* 内藤 part end*/}

        {/* other staff start*/}
        <Grid
          container
          spacing={2}
          style={{
            marginTop: '40px',
          }}>
          <Grid item xs={12} sm={6} md={4} onClick={() => setShowMember1Dialog(true)}>
            <div>
              <img src={TeamImage.staff1} alt={''} className={styles.staffImage1} />
            </div>
            <div className={styles.staffPosition}>COO/CAO</div>
            <div className={styles.staffName}>Ken Foo</div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} onClick={() => setShowMember3Dialog(true)}>
            <div>
              <img src={TeamImage.staff3} alt={''} className={styles.staffImage1} />
            </div>
            <div className={styles.staffPosition}>CIO</div>
            <div className={styles.staffName}>柴田 秀樹</div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} onClick={() => setShowMember4Dialog(true)}>
            <div>
              <img src={TeamImage.staff4} alt={''} className={styles.staffImage1} />
            </div>
            <div className={styles.staffPosition}>CFO</div>
            <div className={styles.staffName}>乾 牧夫</div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} onClick={() => setShowMember5Dialog(true)}>
            <div>
              <img src={TeamImage.staff5} alt={''} className={styles.staffImage1} />
            </div>
            <div className={styles.staffPosition}>CBO</div>
            <div className={styles.staffName}>ケン・マスイ</div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} onClick={() => setShowMember2Dialog(true)}>
            <div>
              <img src={TeamImage.staff2} alt={''} className={styles.staffImage1} />
            </div>
            <div className={styles.staffPosition}>Director/ Art</div>
            <div className={styles.staffName}>三輪昌徳</div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} onClick={() => setShowMember7Dialog(true)}>
            <div>
              <img src={TeamImage.staff7} alt={''} className={styles.staffImage1} />
            </div>
            <div className={styles.staffPosition}>Massive Advisor</div>
            <div className={styles.staffName}>松尾考哲</div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} onClick={() => setShowMember8Dialog(true)}>
            <div>
              <img src={TeamImage.staff8} alt={''} className={styles.staffImage1} />
            </div>
            <div className={styles.staffPosition}>Massive Advisor</div>
            <div className={styles.staffName}>舟引美咲</div>
          </Grid>
          <Grid item xs={12} sm={6} md={4} onClick={() => setShowMember6Dialog(true)}>
            <div>
              <img
                src={TeamImage.staff6}
                alt={''}
                className={styles.staffImage1}
                style={{
                  aspectRatio: '304/380',
                }}
              />
            </div>
            <div className={styles.staffPosition}>Massive Advisor</div>
            <div className={styles.staffName}>村上 憲郎</div>
          </Grid>
        </Grid>
        {/* other staff end*/}
      </div>
    </div>
  );
};

export default OurTeamPart;
