export class ArtImage {
  static readonly ART_IMAGE1 = require('./artImage1.png');
  static readonly ART_IMAGE2 = require('./artImage2.png');
  static readonly ART_IMAGE3 = require('./artImage3.png');
  static readonly ART_IMAGE4 = require('./artImage4.png');
  static readonly ART_IMAGE5 = require('./artImage5.png');
  static readonly ART_IMAGE6 = require('./artImage6.png');
  static readonly ART_IMAGE7 = require('./artImage7.png');
  static readonly ART_IMAGE8 = require('./artImage8.png');
  static readonly ART_IMAGE9 = require('./artImage9.png');
}
