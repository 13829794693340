/**
 * @description homepage about us part
 * @author zqsun
 * @date 2024/7/3
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import DebounceButton from 'react-debounce-button';
import FlexCenter from 'react-flex-center';
import { Link } from 'react-router-dom';
import { AboutUsImage } from 'src/ts/img/aboutUs/aboutUs-image';
import { APP_URLS } from 'src/ts/router/url-router';
import styles from './AboutUsPart.module.scss';

interface AboutUsPartProps {
  showMore: boolean;
}
const AboutUsPart = ({ showMore }: AboutUsPartProps) => {
  return (
    <div id='AboutUsPart' className={styles.root}>
      <div className={'mainRoot'}>
        <h1 className={styles.title}>About Us</h1>
        {/* 会社概要 table start*/}
        <h3 className={styles.subTitle}>会社概要</h3>
        <table className={styles.aboutUsTable}>
          <tbody>
            <tr>
              <th>会社名</th>
              <td>Super Massive Global 株式会社</td>
            </tr>
            <tr>
              <th>所在地</th>
              <td>
                HEAD OFFICE：〒107-0052　東京都港区赤坂3-17-1 いちご赤坂317 ビル4F
                <br />
                ART LOUNGE：〒150-0001　東京都渋谷区神宮前5-6-13 2F
              </td>
            </tr>
            <tr>
              <th>資本金</th>
              <td>309,661,000 円</td>
            </tr>
            <tr>
              <th>代表者</th>
              <td>代表取締役 CEO　内藤 慎人</td>
            </tr>
          </tbody>
        </table>
        {/* 会社概要 table end*/}

        {/* office picture start*/}
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <img src={AboutUsImage.ABOUT_US_IMAGE1} alt={''} className={styles.officeImage} />
            <p className={styles.officeText}>Super Massive Global 株式会社</p>
            <p className={styles.officeText}>〒107-0052</p>
            <p className={styles.officeText}>
              東京都港区赤坂3-17-1 いちご赤坂317 ビル4F{' '}
              <a
                style={{
                  color: 'blue',
                }}
                href='https://www.google.com/maps/search/?api=1&query=東京都港区赤坂3-17-1+いちご赤坂317+ビル4F'
                target='_blank'
                rel='noopener noreferrer'>
                ［MAP］
              </a>
            </p>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={AboutUsImage.ABOUT_US_IMAGE2} alt={''} className={styles.officeImage} />
            <p className={styles.officeText}>Massive Art Lounge</p>
            <p className={styles.officeText}>〒150-0001</p>
            <p className={styles.officeText}>
              東京都渋谷区神宮前5-6-13 2F
              <a
                style={{
                  color: 'blue',
                }}
                href='https://www.google.com/maps/search/?api=1&query=東京都渋谷区神宮前5-6-13+2F'
                target='_blank'
                rel='noopener noreferrer'>
                ［MAP］
              </a>
            </p>
          </Grid>
        </Grid>
        {/* office picture end*/}

        <FlexCenter
          style={{
            paddingTop: '30px',
          }}>
          {showMore && (
            <Link to={APP_URLS.ABOUT_US}>
              <DebounceButton className={'moreButton'}>MORE</DebounceButton>
            </Link>
          )}
        </FlexCenter>
      </div>
    </div>
  );
};

export default AboutUsPart;
