/**
 * @description homepage massive ways
 * @author zqsun
 * @date 2024/7/2
 * @version 1.0
 */

import styles from './MassiveWays.module.scss';

const MassiveWays = () => {
  return (
    <div id='MassiveWays' className={styles.root}>
      <div className={'mainRoot'}>
        <h2 className={styles.title}>Be Massive Together</h2>
        <h2 className={'greenTitle'}>常識を超えろ</h2>
        <p className={styles.subTitle}>
          習慣や常識にとらわれず、世界を変えるチャンスの旅路を、誇りと知性を持って楽しもう
        </p>
        <p className={styles.text}>
          Super Massive
          Global株式会社は唯一無二のエンターテイメントサービスを提供する会社です。あらゆる事業にゲーミフィケーション体験を持ち込み、ユーザーに楽しさを提供することで、世界中の誰もが喜びを享受できるような中立性を軸にしています。またそこにデータドリブンな要素を入れることでユーザーをより深く理解してサービスを創り出しています。
        </p>
      </div>
    </div>
  );
};

export default MassiveWays;
