/**
 * @description
 * @author zqsun
 * @date 2024/7/22
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import { NEWS_DATA } from 'src/ts/constant/news-constant';
import NewsOne from 'src/tsx/views/components/modules/newsPart/NewsOne';
import styles from './NewsMainPart.module.scss';

const NewsMainPart = () => {
  return (
    <div id='NewsMainPart' className={styles.root}>
      <div className={'mainRoot'}>
        <h2 className={styles.title}>News</h2>
      </div>

      <div
        style={{
          backgroundColor: 'white',
        }}>
        <div
          className={'mainRoot'}
          style={{
            paddingTop: '50px',
            paddingBottom: '50px',
          }}>
          <h2 className={styles.subTitle}>新着情報</h2>
        </div>

        <div className={'mainRoot'}>
          <Grid container>
            {NEWS_DATA.reverse().map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <NewsOne picture={item.picture} title={item.title} time={item.time} text={item.text} link={item.link} />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default NewsMainPart;
