/**
 * @description massive map page
 * @author zqsun
 * @date 2024/7/4
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import DebounceButton from 'react-debounce-button';
import FlexCenter from 'react-flex-center';
import { Link } from 'react-router-dom';
import TitlePath from 'src/ts/img/TitlePath';
import { ServiceImage } from 'src/ts/img/service/service-image';
import { APP_URLS } from 'src/ts/router/url-router';
import Footer from 'src/tsx/views/components/atoms/Footer';
import Header from 'src/tsx/views/components/atoms/Header';
import ScrollToTop from 'src/tsx/views/components/atoms/ScrollToTop';
import styles from './OpenEXPOPage.module.scss';

const OpenEXPOPage = () => {
  return (
    <div id='OpenEXPOPage' className={styles.root}>
      <Header />
      <main
        id='HomepageMain'
        className={'homepageMain'}
        style={{
          background: 'rgba(242, 242, 242, 1)',
        }}>
        <nav className={'nav'}>
          <ul>
            <Link to={APP_URLS.DEFAULT_URL}>TOP</Link>
            <span>{'  >  '}</span>
            <span>OPEN PORTAL EXPO</span>
          </ul>
        </nav>
        <div className={clsx('mainRoot', styles.mapMain1)}>
          <Grid container>
            <Grid item xs={12} md={6} className={styles.mapMain1Text}>
              <h2 className={styles.title}>OPEN PORTAL EXPO</h2>
              <p>
                エキスポに来場したユーザーが会場内で楽しめる体験型ゲームアプリ。提供ブースを出展する企業様にはデータを提供可能となる世界初のエンタメエキスポです。
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <FlexCenter>
                <img src={ServiceImage.SERVICE_IMAGE18} alt={'MassiveMap'} />
              </FlexCenter>
            </Grid>
          </Grid>
        </div>
      </main>
      <div className={clsx('mainRoot', styles.mapMain2)}>
        <Grid
          container
          style={{
            alignItems: 'center',
          }}>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              marginTop: 150,
              marginBottom: 50,
            }}>
            <h2
              style={{
                fontSize: '20px',
                lineHeight: '30px',
                color: 'rgb(51, 198, 198)',
              }}>
              <TitlePath />
              OPEN PORTAL EXPOの特徴
            </h2>
            <h2
              style={{
                fontSize: '28px',
                lineHeight: '42px',
              }}>
              Gamification × Data
            </h2>
            <p>
              全ジャンルのオタク&アーティストのためのMAIN Culture
              EXPOです。オフラインRPGゲームアプリを使い差別化されたデータマーケティングを可能にします。
            </p>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
        </Grid>
      </div>
      <div className={'mainRoot'}>
        <Grid
          container
          style={{
            paddingBottom: 20,
          }}>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              marginBottom: 10,
            }}>
            <div className={styles.mapPaperOne}>
              <FlexCenter>
                <img
                  src={ServiceImage.SERVICE_IMAGE11}
                  alt={'MassiveMap'}
                  style={{
                    width: 'auto',
                    height: '100px',
                  }}
                />
              </FlexCenter>
              <p className={styles.paperTitle}>会場にある QRコードをスキャン</p>
              <p className={styles.paperText}>飲食・ホテル・ショップなど、アプリ内で予約から決済までワンストップ。</p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              marginBottom: 10,
            }}>
            <div className={styles.mapPaperOne}>
              <FlexCenter>
                <img
                  src={ServiceImage.SERVICE_IMAGE12}
                  alt={'MassiveMap'}
                  style={{
                    width: 'auto',
                    height: '100px',
                  }}
                />
              </FlexCenter>
              <p className={styles.paperTitle}>趣味嗜好データ</p>
              <p className={styles.paperText}>個人の趣味嗜好に合わせてお気に入りの場所をスポット表示。（位置連動型）</p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            style={{
              marginBottom: 10,
            }}>
            <div className={styles.mapPaperOne}>
              <FlexCenter>
                <img
                  src={ServiceImage.SERVICE_IMAGE13}
                  alt={'MassiveMap'}
                  style={{
                    width: 'auto',
                    height: '100px',
                  }}
                />
              </FlexCenter>
              <p className={styles.paperTitle}>ユーザアカウントデータ</p>
              <p className={styles.paperText}>ユーザーの性別、年齢、住所、年収等のデータが取得可能。</p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={'mainRoot'}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <p
              style={{
                fontSize: '20px',
                lineHeight: '30px',
                color: 'rgb(51, 198, 198)',
              }}>
              <TitlePath />
              BENEFIT!
            </p>
            <h2
              className={styles.paperTitle}
              style={{
                fontWeight: 'bold',
                textAlign: 'left',
                marginBottom: '20px',
              }}>
              貯めたポイントでaiが育ったり好きなグッズや会場内で使用可能なクーポンと交換可能
            </h2>
          </Grid>
          <Grid item xs={12} md={5}>
            <FlexCenter>
              <img src={ServiceImage.SERVICE_IMAGE16} alt={'MassiveMap'} className={styles.mapPhone} />
            </FlexCenter>
          </Grid>
        </Grid>
      </div>

      <div
        style={{
          background: 'rgba(242, 242, 242, 1)',
        }}>
        <div
          className={'mainRoot'}
          style={{
            paddingBottom: 50,
            paddingTop: 50,
          }}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <p
                style={{
                  fontSize: '20px',
                  lineHeight: '30px',
                  color: 'rgb(51, 198, 198)',
                }}>
                <TitlePath />
                解析ツール
              </p>
              <h2
                className={styles.paperTitle}
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  marginBottom: '20px',
                }}>
                出展する企業様にはユーザーの行動データを提供
              </h2>

              <p className={styles.paperText}>
                来場したユーザーの行動データをマーケティングに活用することにより、今後の商品開発や販促活動に活用できます。
              </p>
            </Grid>
            <Grid item xs={12} md={5}></Grid>
          </Grid>
        </div>
      </div>
      <FlexCenter className={styles.footTextMain}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <FlexCenter>
              <p className={styles.footText}>観光マップ事業についてのご依頼・お問い合わせはこちら</p>
            </FlexCenter>
          </Grid>
          <Grid item xs={12} md={4}>
            <FlexCenter>
              <Link to={APP_URLS.CONTACT}>
                <DebounceButton className={styles.footButton}>MORE</DebounceButton>
              </Link>
            </FlexCenter>
          </Grid>
        </Grid>
      </FlexCenter>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default OpenEXPOPage;
