import AdvertisingPage from 'src/tsx/views/pages/advertising/AdvertisingPage';
import ArtPage from 'src/tsx/views/pages/art/ArtPage';
import ArtMirage from 'src/tsx/views/pages/artEvent/ArtMirage';
import ContactPage from 'src/tsx/views/pages/contact/ContactPage';
import Homepage from 'src/tsx/views/pages/homepage/Homepage.view';
import MassiveMapPage from 'src/tsx/views/pages/massiveMap/MassiveMapPage';
import AboutUsPage from 'src/tsx/views/pages/member/AboutUsPage';
import NewsPage from 'src/tsx/views/pages/news/NewsPage';
import OpenEXPOPage from 'src/tsx/views/pages/openEXPO/OpenEXPOPage';
import PrivacyPolicy from 'src/tsx/views/pages/privacyPolicy/PrivacyPolicy';
import { APP_URLS } from './url-router';

export interface Router {
  path: string;
  exact: boolean;
  text?: string;
  render: () => JSX.Element;
}

const routers: Router[] = [
  {
    path: APP_URLS.ABOUT_US,
    exact: false,
    render: AboutUsPage,
  },
  {
    path: APP_URLS.MASSIVE_MAP,
    exact: false,
    render: MassiveMapPage,
  },
  {
    path: APP_URLS.OPEN_EXPO,
    exact: false,
    render: OpenEXPOPage,
  },
  {
    path: APP_URLS.ADVERTISING,
    exact: false,
    render: AdvertisingPage,
  },
  {
    path: APP_URLS.ART_MIRAGE,
    exact: false,
    render: ArtMirage,
  },
  {
    path: APP_URLS.ART,
    exact: false,
    render: ArtPage,
  },
  {
    path: APP_URLS.CONTACT,
    exact: false,
    render: ContactPage,
  },
  {
    path: APP_URLS.PRIVACY_POLICY,
    exact: false,
    render: PrivacyPolicy,
  },
  {
    path: APP_URLS.NEWS,
    exact: false,
    render: NewsPage,
  },
  {
    path: APP_URLS.DEFAULT_URL,
    exact: false,
    render: Homepage,
  },
];

export { routers };
