/**
 * @description advertising page
 * @author zqsun
 * @date 2024/7/9
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import DebounceButton from 'react-debounce-button';
import FlexCenter from 'react-flex-center';
import { FaRegCircle } from 'react-icons/fa';
import { IoCloseSharp } from 'react-icons/io5';
import { TbTriangle } from 'react-icons/tb';
import { Link } from 'react-router-dom';
import TitlePath from 'src/ts/img/TitlePath';
import { ServiceImage } from 'src/ts/img/service/service-image';
import { APP_URLS } from 'src/ts/router/url-router';
import Footer from 'src/tsx/views/components/atoms/Footer';
import Header from 'src/tsx/views/components/atoms/Header';
import styles from './AdvertisingPage.module.scss';

const AdvertisingPage = () => {
  return (
    <div id='AdvertisingPage' className={styles.root}>
      <Header />
      <main
        id='HomepageMain'
        className={'homepageMain'}
        style={{
          background: 'rgba(242, 242, 242, 1)',
        }}>
        <nav className={'nav'}>
          <ul>
            <Link to={APP_URLS.DEFAULT_URL}>TOP</Link>
            <span>{'  >  '}</span>
            <span>広告事業</span>
          </ul>
        </nav>
        <div className={clsx('mainRoot', styles.mapMain1)}>
          <Grid container>
            <Grid item xs={12} md={6} className={styles.mapMain1Text}>
              <h2 className={styles.title}>広告事業</h2>
              <p>
                他社と比較して、我々の広告運用手数料は平均して5％から10％も安価に提供することが可能です。さらに、万が一アカウントが停止された場合でも、無制限に新しいアカウントを発行できるという圧倒的な強みを持っています。この柔軟性とコスト効率の高さが、他に類を見ない我々のサービスの魅力です。
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <FlexCenter>
                <img src={ServiceImage.SERVICE_IMAGE14} alt={'MassiveMap'} />
              </FlexCenter>
            </Grid>
          </Grid>
        </div>
      </main>
      {/* MASSIVE 広告事業の特徴 start*/}
      <div className={clsx('mainRoot')}>
        <Grid
          container
          style={{
            alignItems: 'center',
          }}>
          <Grid
            item
            xs={12}
            md={7}
            style={{
              marginTop: 150,
              marginBottom: 50,
            }}>
            <h2
              style={{
                fontSize: '20px',
                lineHeight: '30px',
                color: 'rgb(51, 198, 198)',
              }}>
              <TitlePath />
              MASSIVE 広告事業の特徴
            </h2>
            <h2
              style={{
                fontSize: '28px',
                lineHeight: '42px',
                fontWeight: 'bold',
              }}>
              弊社で運用した場合、
              <br />
              5%-10%/月のコストダウンを実現できます!
            </h2>
            <Grid
              container
              style={{
                marginTop: 30,
              }}>
              {/* 通常の広告運用手数料 start*/}
              <Grid item xs={12} md={6} className={styles.advertisingOtherMain}>
                <FlexCenter
                  style={{
                    width: '80%',
                    margin: 'auto',
                  }}>
                  <h3
                    style={{
                      fontSize: '20px',
                      lineHeight: '30px',
                      paddingBottom: 10,
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: 20,
                      border: '#242424 1px solid',
                      borderRadius: 10,
                      textAlign: 'center',
                      width: 'fit-content',
                    }}>
                    通常の広告運用手数料
                  </h3>
                </FlexCenter>
                <p
                  className={styles.paperTitle}
                  style={{
                    width: '80%',
                    margin: 'auto',
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}>
                  <span className={styles.paperTextNumber}>15〜20%</span>
                  /月
                </p>

                <p
                  style={{
                    marginTop: 30,
                    width: '80%',
                    margin: 'auto',
                  }}
                  className={styles.paperText}>
                  説明が入ります。説明が入ります。説明が入ります。説明が入ります。
                </p>
              </Grid>
              {/* 通常の広告運用手数料 end*/}

              {/* MASSIVEの広告運用手数料 start*/}
              <Grid item xs={12} md={6}>
                <FlexCenter
                  style={{
                    width: '80%',
                    margin: 'auto',
                  }}>
                  <h3
                    style={{
                      fontSize: '20px',
                      lineHeight: '30px',
                      paddingBottom: 10,
                      paddingTop: 10,
                      paddingLeft: 20,
                      paddingRight: 20,
                      border: 'rgb(255, 89, 59) 1px solid',
                      borderRadius: 10,
                      textAlign: 'center',
                      width: 'fit-content',
                      color: 'rgb(255, 89, 59)',
                    }}>
                    MASSIVEの広告運用手数料
                  </h3>
                </FlexCenter>
                <p
                  className={styles.paperTitle}
                  style={{
                    color: 'rgb(255, 89, 59)',
                    width: '80%',
                    margin: 'auto',
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}>
                  <span className={styles.paperTextNumber}>10%</span>
                  /月
                </p>
              </Grid>
              {/* MASSIVEの広告運用手数料 end*/}
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}></Grid>
        </Grid>

        <div
          style={{
            marginBottom: 10,
            marginTop: 10,
          }}>
          <img src={ServiceImage.SERVICE_IMAGE17} alt={'Advertising'} />
        </div>
      </div>
      {/* MASSIVE 広告事業の特徴 end*/}

      {/* MASSIVEの広告運用が強い理由 start*/}
      <section
        style={{
          background: 'rgba(242, 242, 242, 1)',
        }}>
        <div
          className={'mainRoot'}
          style={{
            paddingBottom: 50,
            paddingTop: 50,
          }}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <p
                style={{
                  fontSize: '20px',
                  lineHeight: '30px',
                  color: 'rgb(51, 198, 198)',
                }}>
                <TitlePath />
                MASSIVEの広告運用が強い理由
              </p>
              <h2
                className={styles.paperTitle}
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  marginBottom: '20px',
                }}>
                MASSIVEはアジア最大の PR会社「BlueFocus」と提携
              </h2>

              <p className={styles.paperText}>
                アジア最大のPR会社「BlueFocus」とAdTech戦略パートナーを締結したことで、通常の広告運用手数料よりも安価に提供することが可能です。現在ご使用の広告アカウントはそのままに少ない手続きで運用手数料を抑えることができます。詳細はお問い合わせください。
              </p>
            </Grid>
            <Grid item xs={12} md={5}></Grid>
          </Grid>
        </div>
      </section>
      {/* MASSIVEの広告運用が強い理由 end*/}

      {/* MASSIVE の広告アカウント比較 start*/}
      <section
        style={{
          background: 'rgba(242, 242, 242, 1)',
        }}>
        <div
          className={'mainRoot'}
          style={{
            paddingBottom: 50,
          }}>
          <Grid container>
            <Grid item xs={12} md={7}>
              <p
                style={{
                  fontSize: '20px',
                  lineHeight: '30px',
                  color: 'rgb(51, 198, 198)',
                }}>
                <TitlePath />
                MASSIVEの広告アカウント比較
              </p>
              <h2
                className={styles.paperTitle}
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  marginBottom: '20px',
                }}>
                通常アカウントと比較して多数のメリットを提供
              </h2>

              <p className={styles.paperText}>
                下表に記載の通り、当社のアカウントを使用することにより３点のメリットを提供します。
                <br />
                ①無制限にアカウントを新規開設することができます。デポジット残高も自由に移管できます。
                <br />
                ②日本国外の審査チームと連携が取れ、AIによる人工審査に強くなります。
                <br />
                ③当社アカウントを利用するだけで営業利益率が平均25%以上アップします。
              </p>
            </Grid>
            <Grid item xs={12} md={5}></Grid>
          </Grid>

          {/* 比较table start*/}
          <Grid container>
            <Grid item xs={12}>
              <table className={styles.paperTable}>
                <thead>
                  <tr>
                    <th
                      style={{
                        background: 'rgba(242, 242, 242, 1)',
                        height: '80px',
                        verticalAlign: 'bottom',
                      }}>
                      <div
                        style={{
                          background: '#cecece',
                          height: '80px',
                        }}></div>
                    </th>
                    <th
                      style={{
                        background: 'rgba(242, 242, 242, 1)',
                        height: '80px',
                        verticalAlign: 'bottom',
                      }}>
                      <FlexCenter
                        style={{
                          background: '#cecece',
                          height: '80px',
                          padding: '10px',
                        }}>
                        通常アカウント
                      </FlexCenter>
                    </th>
                    <th
                      style={{
                        background: 'rgba(242, 242, 242, 1)',
                      }}>
                      <FlexCenter
                        style={{
                          background: '#C8DBF4',
                          borderTopLeftRadius: '10px',
                          borderTopRightRadius: '10px',
                          height: '110px',
                          padding: '10px',
                        }}>
                        Bluefocusアカウント
                      </FlexCenter>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                      }}>
                      開設可能アカウント
                    </td>
                    <td>
                      <p
                        style={{
                          fontSize: '18px',
                          fontWeight: 'bold',
                        }}>
                        1アカウントのみ
                      </p>
                      <p>一度凍結すると復旧が不可</p>
                    </td>
                    <td>
                      <p
                        style={{
                          fontSize: '24px',
                          fontWeight: 'bold',
                          color: '#FF593B',
                          marginBottom: '10px',
                        }}>
                        無制限
                      </p>
                      <p>無限に新規開設ができ、</p>
                      <p>デポジット残金を自由に移管できる</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                      }}>
                      審査への強さ
                    </td>
                    <td>
                      <FlexCenter
                        style={{
                          marginBottom: '20px',
                        }}>
                        <TbTriangle size={37} color={'#65B456'} />
                      </FlexCenter>
                      <p>AI+人工の審査をクリアする必要がある</p>
                    </td>
                    <td>
                      <FlexCenter
                        style={{
                          marginBottom: '20px',
                        }}>
                        <FaRegCircle color={'#FF593B'} size={37} />
                      </FlexCenter>
                      <p>海外にある審査チームと連携を取 ることで、</p>
                      <p>人工審査に強い</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                      }}>
                      費用対効果
                    </td>
                    <td>
                      <FlexCenter>
                        <IoCloseSharp size={58} color={'#242424'} />
                      </FlexCenter>
                      <p>特にメリットなし</p>
                    </td>
                    <td>
                      <FlexCenter
                        style={{
                          marginBottom: '20px',
                        }}>
                        <FaRegCircle color={'#FF593B'} size={37} />
                      </FlexCenter>
                      <p>利用するだけで営業利益率UP (25%以上)に貢献</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
          {/* 比较table end*/}
        </div>
      </section>
      {/* MASSIVE の広告アカウント比較 end*/}
      {/* 事例紹介 start*/}
      <div className={clsx('mainRoot')}>
        <Grid
          container
          style={{
            alignItems: 'center',
          }}>
          <Grid
            item
            xs={12}
            md={7}
            style={{
              marginTop: 50,
              marginBottom: 50,
            }}>
            <h2
              style={{
                fontSize: '20px',
                lineHeight: '30px',
                color: 'rgb(51, 198, 198)',
              }}>
              <TitlePath />
              事例紹介
            </h2>
            <p
              className={styles.paperText}
              style={{
                marginTop: 30,
              }}>
              説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。説明が入ります。
            </p>
          </Grid>
        </Grid>
      </div>
      {/* 事例紹介 end*/}
      <FlexCenter className={styles.footTextMain}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <FlexCenter>
              <p className={styles.footText}>広告事業についてのご依頼・お問い合わせはこちら</p>
            </FlexCenter>
          </Grid>
          <Grid item xs={12} md={4}>
            <FlexCenter>
              <DebounceButton className={styles.footButton}>MORE</DebounceButton>
            </FlexCenter>
          </Grid>
        </Grid>
      </FlexCenter>
      <Footer />
    </div>
  );
};

export default AdvertisingPage;
