import ReactDOM from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import 'src/tsx/style/arrow.scss';
import 'src/tsx/style/button.scss';
import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <RecoilRoot>
    <App />
  </RecoilRoot>
);

reportWebVitals();
