/**
 * @description art page
 * @author zqsun
 * @date 2024/7/9
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import DebounceButton from 'react-debounce-button';
import FlexCenter from 'react-flex-center';
import { Link } from 'react-router-dom';
import TitlePath from 'src/ts/img/TitlePath';
import { ArtImage } from 'src/ts/img/art/art-image';
import { APP_URLS } from 'src/ts/router/url-router';
import Footer from 'src/tsx/views/components/atoms/Footer';
import Header from 'src/tsx/views/components/atoms/Header';
import ScrollToTop from 'src/tsx/views/components/atoms/ScrollToTop';
import NewsOne from 'src/tsx/views/components/modules/newsPart/NewsOne';
import styles from './ArtPage.module.scss';

const ArtPage = () => {
  return (
    <div id='ArtPage' className={styles.root}>
      <Header from={'Art'} />
      <main
        className={'homepageMain'}
        style={{
          background: '#363636',
          paddingBottom: 0,
        }}>
        <nav className={'nav'}>
          <ul>
            <Link to={APP_URLS.DEFAULT_URL}>TOP</Link>
            <span>{'  >  '}</span>
            <span>イベント企画 アートラウンジ運営</span>
          </ul>
        </nav>
        <div className={'mainRoot'}>
          <div>
            <Grid
              container
              style={{
                paddingTop: 50,
              }}
              spacing={2}>
              <Grid item xs={12} md={7}>
                <h1
                  className={styles.title}
                  style={{
                    color: '#33C6C6',
                  }}>
                  イベント企画
                </h1>
                <h1
                  className={styles.title}
                  style={{
                    color: '#33C6C6',
                  }}>
                  アートラウンジ運営
                </h1>

                <p
                  className={styles.text}
                  style={{
                    marginTop: 10,
                  }}>
                  会員及び招待者限定のアートラウンジを運営。アート展示や音楽鑑賞、飲食が可能な特別な空間を提供します。また、アーティストとともに企業やブランドと提携したアートイベントを企画制作しています。
                </p>
              </Grid>
              <Grid item xs={12} md={5}>
                <img src={ArtImage.ART_IMAGE1} alt={'Art'} className={styles.artImage1} />
              </Grid>
            </Grid>
          </div>
        </div>

        {/* ART EVENT PLANNING start*/}
        <section
          style={{
            background: '#363636',
          }}>
          <div className={'mainRoot'}>
            <Grid container className={styles.artEventPlanningMain} spacing={2}>
              <Grid item xs={12} md={7}>
                <h1 className={styles.artEventPlanningTitle}>ART EVENT PLANNING</h1>
                <h2
                  style={{
                    fontSize: '20px',
                    lineHeight: '30px',
                    color: 'rgb(51, 198, 198)',
                  }}>
                  <TitlePath />
                  イベント企画事業の特徴
                </h2>
                <h3 className={styles.artEventPlanningSubTitle}>アートイベントの企画制作及び運営を行います。</h3>
                <p className={styles.artEventPlanningText}>
                  アートイベントの企画制作・運営、アート作品の販売、販促物やポートフォリオの制作、メディアPR・集客、作品配送等、アートに関連した様々なことを行なっています。
                </p>
              </Grid>
              <Grid item xs={12} md={5}>
                <FlexCenter>
                  <img src={ArtImage.ART_IMAGE2} alt={'Art'} className={styles.artImage2} />
                </FlexCenter>
              </Grid>
            </Grid>
          </div>
        </section>
        {/* ART EVENT PLANNING end*/}

        {/* on view part start*/}
        <section
          style={{
            background: '#363636',
          }}>
          <div className={'mainRoot'}>
            <h2 className={styles.title}>On View</h2>
            <Grid container className={styles.onViewMain}>
              <Grid item xs={12} md={5}>
                <img src={ArtImage.ART_IMAGE3} alt={'Art'} className={styles.artImage3} />
              </Grid>
              <Grid item xs={12} md={7}>
                <div
                  style={{
                    width: '80%',
                    margin: 'auto',
                  }}>
                  <p
                    style={{
                      fontSize: '20px',
                    }}>
                    Mirage
                  </p>
                  <h2 className={styles.artEventPlanningSubTitle}>
                    名和晃平とダミアン・ジャレによる コラボレーション三部作に続く新たな展開
                  </h2>

                  <p
                    className={styles.artEventPlanningText}
                    style={{
                      marginTop: 20,
                    }}>
                    Damien Jalet | Kohei Nawa
                    <br />
                    会場:Theater010 公演時間:約90分(間奏あり)
                    <br />
                    期間：2024年9月-10月
                  </p>

                  <Grid container>
                    <Grid item xs={12} md={6}>
                      <Link to={APP_URLS.ART_MIRAGE}>
                        <FlexCenter>
                          <DebounceButton className={styles.moreButton}>More</DebounceButton>
                        </FlexCenter>
                      </Link>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FlexCenter>
                        <a
                          href='https://eplus.jp/sf/detail/4143000001-P0030001'
                          target='_blank'
                          rel='noopener noreferrer'>
                          <DebounceButton
                            className={styles.moreButton}
                            style={{
                              background: 'rgb(51, 198, 198)',
                            }}>
                            特設ページへ
                          </DebounceButton>
                        </a>
                      </FlexCenter>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
        {/* on view part end*/}

        {/* ARCHIVE part start*/}
        <section
          style={{
            background: '#363636',
            paddingBottom: 50,
          }}>
          <div className={'mainRoot'}>
            <h2 className={styles.title}>ARCHIVE</h2>

            <Grid container>
              <Grid item xs={12} md={3}>
                <NewsOne
                  picture={ArtImage.ART_IMAGE3}
                  title={'名和晃平とダミアン・ジャレによるコラボレーション三部作に続く新たな展開'}
                  time={''}
                  link={''}
                  from={'Art'}
                />
              </Grid>
            </Grid>

            <FlexCenter
              style={{
                marginTop: 20,
              }}>
              <DebounceButton className={styles.moreButton}>More</DebounceButton>
            </FlexCenter>
          </div>
        </section>
        {/* ARCHIVE part end*/}

        <div>
          <img
            src={ArtImage.ART_IMAGE4}
            alt={''}
            style={{
              width: '100%',
            }}
          />
        </div>

        {/* ART LOUNGE part start*/}
        <section
          style={{
            background: '#F2F2F2',
          }}>
          <div className={'mainRoot'}>
            <div className={styles.artEventPlanningMain}>
              <h1
                className={styles.title}
                style={{
                  color: '#242424',
                }}>
                ART LOUNGE
              </h1>
              <h2
                style={{
                  fontSize: '20px',
                  lineHeight: '30px',
                  color: 'rgb(51, 198, 198)',
                }}>
                <TitlePath />
                イベント企画事業の特徴
              </h2>
              <h3
                className={styles.artEventPlanningSubTitle}
                style={{
                  color: '#242424',
                }}>
                招待制のアートラウンジを運営しています。
              </h3>
              <p
                className={styles.artEventPlanningText}
                style={{
                  marginTop: 20,
                  color: '#242424',
                }}>
                招待制のアートラウンジではここでしか見ることのできない気鋭の作家の作品を月替わりで展示しています。ギャラリー空間だけでなく飲食や音楽鑑賞も可能です。詳細はお問い合わせください。
              </p>
            </div>
          </div>
        </section>
        {/* ART LOUNGE part end*/}

        {/* office pictures start*/}
        <section>
          <Grid container>
            <Grid item xs={12} md={2.4}>
              <img src={ArtImage.ART_IMAGE5} alt={''} className={styles.officePicture} />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <img src={ArtImage.ART_IMAGE6} alt={''} className={styles.officePicture} />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <img src={ArtImage.ART_IMAGE7} alt={''} className={styles.officePicture} />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <img src={ArtImage.ART_IMAGE8} alt={''} className={styles.officePicture} />
            </Grid>
            <Grid item xs={12} md={2.4}>
              <img src={ArtImage.ART_IMAGE9} alt={''} className={styles.officePicture} />
            </Grid>
          </Grid>
        </section>
        {/* office pictures end*/}

        {/* お問い合わせ start*/}
        <section
          style={{
            background: 'rgb(51, 198, 198)',
          }}>
          <div className={'mainRoot'}>
            <Grid
              container
              style={{
                paddingTop: 50,
                paddingBottom: 50,
              }}>
              <Grid item xs={12} md={7}>
                <h1
                  className={styles.artEventPlanningSubTitle}
                  style={{
                    color: '#F2F2F2',
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}>
                  アートラウンジ運営・イベント企画事業についてのご依頼・お問い合わせはこちら
                </h1>
              </Grid>
              <Grid item xs={12} md={5}>
                <FlexCenter>
                  <Link to={APP_URLS.CONTACT}>
                    <DebounceButton
                      className={styles.moreButton}
                      style={{
                        background: '#F2F2F2',
                        color: '#242424',
                      }}>
                      MORE
                    </DebounceButton>
                  </Link>
                </FlexCenter>
              </Grid>
            </Grid>
          </div>
        </section>
        {/* お問い合わせ end*/}
      </main>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default ArtPage;
