export class ServiceImage {
  // staff default
  static readonly SERVICE_IMAGE1 = require('./serviceImage1.png');
  static readonly SERVICE_IMAGE2 = require('./serviceImage2.png');
  static readonly SERVICE_IMAGE3 = require('./serviceImage3.png');
  static readonly SERVICE_IMAGE4 = require('./serviceImage4.png');
  static readonly SERVICE_IMAGE5 = require('./serviceImage5.png');
  static readonly SERVICE_IMAGE6 = require('./serviceImage6.png');
  static readonly SERVICE_IMAGE7 = require('./serviceImage7.png');
  static readonly SERVICE_IMAGE8 = require('./serviceImage8.png');
  static readonly SERVICE_IMAGE9 = require('./serviceImage9.png');
  static readonly SERVICE_IMAGE10 = require('./serviceImage10.png');
  static readonly SERVICE_IMAGE11 = require('./serviceImage11.png');
  static readonly SERVICE_IMAGE12 = require('./serviceImage12.png');
  static readonly SERVICE_IMAGE13 = require('./serviceImage13.png');
  // service  phone
  static readonly SERVICE_IMAGE14 = require('./serviceImage14.png');
  // service phone video
  static readonly SERVICE_IMAGE15 = require('./serviceImage15.png');
  // expo service
  static readonly SERVICE_IMAGE16 = require('./serviceImage16.png');
  // ad service
  static readonly SERVICE_IMAGE17 = require('./serviceImage17.png');
  static readonly SERVICE_IMAGE18 = require('./serviceImage18.png');
}
