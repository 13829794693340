/**
 * @fileOverview 共通的Dialog的挂载页面
 * @author zqsun
 * @version 1.0
 */
import { Dialog } from '@mui/material';
import ErrorAlert from 'new-verse-front-library/dist/tsx/alert/ErrorAlert.view';
import InfoAlert from 'new-verse-front-library/dist/tsx/alert/InfoAlert.view';
import IsLoadingPage from 'new-verse-front-library/dist/tsx/alert/IsLoadingPage';
import SuccessAlert from 'new-verse-front-library/dist/tsx/alert/SuccessAlert.view';
import WarningAlert from 'new-verse-front-library/dist/tsx/alert/WarningAlert.view';
import NewConfirmDialog from 'new-verse-front-library/dist/tsx/dialog/NewConfirmDialog';
import { ReactNode } from 'react';
import { useDialogStore } from 'src/ts/stores/dialog-store';
import Staff1 from 'src/tsx/views/components/modules/staffs/Staff1';
import Staff2 from 'src/tsx/views/components/modules/staffs/Staff2';
import Staff3 from 'src/tsx/views/components/modules/staffs/Staff3';
import Staff4 from 'src/tsx/views/components/modules/staffs/Staff4';
import Staff5 from 'src/tsx/views/components/modules/staffs/Staff5';
import Staff6 from 'src/tsx/views/components/modules/staffs/Staff6';
import Staff7 from 'src/tsx/views/components/modules/staffs/Staff7';
import Staff8 from 'src/tsx/views/components/modules/staffs/Staff8';
import styles from './DialogView.module.scss';

interface DialogShowProps {
  children: ReactNode;
}

const DialogView = ({ children }: DialogShowProps) => {
  /* state start*/
  // alert 弹窗 state
  const { successAlert, infoAlert, warningAlert, errorAlert } = useDialogStore();
  // is loading state
  const { isLoading } = useDialogStore();
  // confirm dialog state
  const { confirmDialog } = useDialogStore();
  // staff dialog state
  const {
    showMember1Dialog,
    showMember2Dialog,
    showMember3Dialog,
    showMember4Dialog,
    showMember5Dialog,
    showMember6Dialog,
    showMember7Dialog,
    showMember8Dialog,
  } = useDialogStore();
  /* state end*/
  return (
    <div id='DialogView' className={styles.root}>
      <div
        style={{
          zIndex: 1,
        }}>
        {children}
      </div>

      {/* 共通警告框的Dialog*/}
      <section
        id='alert-section'
        style={{
          zIndex: 1500,
          position: 'fixed',
          top: 100,
          width: '100%',
          display: successAlert || infoAlert || warningAlert || errorAlert || isLoading ? 'flex' : 'none',
          justifyContent: 'center',
        }}>
        {successAlert && <SuccessAlert customFadeOutTime={0} customStableTime={1000} />}
        {infoAlert && <InfoAlert />}
        {warningAlert && <WarningAlert />}
        {errorAlert && <ErrorAlert />}
        {isLoading && <IsLoadingPage />}
        {confirmDialog.open && <NewConfirmDialog {...confirmDialog} />}
      </section>
      {/* 共通信息框的Dialog*/}
      <section id='dialog-section'>
        {showMember1Dialog && (
          <Dialog className={styles.infoDialog} open={true} onClose={() => {}}>
            <Staff1 />
          </Dialog>
        )}
        {showMember2Dialog && (
          <Dialog className={styles.infoDialog} open={true} onClose={() => {}}>
            <Staff2 />
          </Dialog>
        )}
        {showMember3Dialog && (
          <Dialog className={styles.infoDialog} open={true} onClose={() => {}}>
            <Staff3 />
          </Dialog>
        )}
        {showMember4Dialog && (
          <Dialog className={styles.infoDialog} open={true} onClose={() => {}}>
            <Staff4 />
          </Dialog>
        )}
        {showMember5Dialog && (
          <Dialog className={styles.infoDialog} open={true} onClose={() => {}}>
            <Staff5 />
          </Dialog>
        )}
        {showMember6Dialog && (
          <Dialog className={styles.infoDialog} open={true} onClose={() => {}}>
            <Staff6 />
          </Dialog>
        )}
        {showMember7Dialog && (
          <Dialog className={styles.infoDialog} open={true} onClose={() => {}}>
            <Staff7 />
          </Dialog>
        )}
        {showMember8Dialog && (
          <Dialog className={styles.infoDialog} open={true} onClose={() => {}}>
            <Staff8 />
          </Dialog>
        )}
      </section>
    </div>
  );
};

export default DialogView;
