/**
 * @description 抽屉组件上方的滚动图标组件
 * @author zqsun
 * @date 2023/06/13
 * @version 1.0
 */

import React, { useId } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

interface DialogCloseIconProps {
  onClick: () => void;
  children?: React.ReactNode;
}

const DialogCloseIcon = ({ onClick, children }: DialogCloseIconProps) => {
  const componentId: string = useId();
  return (
    <div id={`DialogCloseIcon-${componentId}`} className='scrollDown'>
      {children}
      <AiOutlineClose className={'scrollDownIcon'} size={50} onClick={onClick} color={'#242424'} />
    </div>
  );
};

export default DialogCloseIcon;
