/**
 * @description about us page
 * @author zqsun
 * @date 2024/7/3
 * @version 1.0
 */

import Footer from 'src/tsx/views/components/atoms/Footer';
import Header from 'src/tsx/views/components/atoms/Header';
import ScrollToTop from 'src/tsx/views/components/atoms/ScrollToTop';
import AboutUsPart from 'src/tsx/views/pages/homepage/AboutUsPart';
import OurTeamPart from 'src/tsx/views/pages/homepage/OurTeamPart';
import styles from './AboutUsPage.module.scss';

const AboutUsPage = () => {
  return (
    <div id='AboutUsPage' className={styles.root}>
      <Header />
      <AboutUsPart showMore={false} />
      <OurTeamPart />
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default AboutUsPage;
