import NewsDetail1 from 'src/tsx/views/pages/news/NewsDetail1';
import NewsDetail10 from 'src/tsx/views/pages/news/NewsDetail10';
import NewsDetail2 from 'src/tsx/views/pages/news/NewsDetail2';
import NewsDetail3 from 'src/tsx/views/pages/news/NewsDetail3';
import NewsDetail4 from 'src/tsx/views/pages/news/NewsDetail4';
import NewsDetail5 from 'src/tsx/views/pages/news/NewsDetail5';
import NewsDetail6 from 'src/tsx/views/pages/news/NewsDetail6';
import NewsDetail7 from 'src/tsx/views/pages/news/NewsDetail7';
import NewsDetail8 from 'src/tsx/views/pages/news/NewsDetail8';
import NewsDetail9 from 'src/tsx/views/pages/news/NewsDetail9';
import NewsMainPart from 'src/tsx/views/pages/news/NewsMainPart';
import { APP_URLS } from './url-router';

export interface Router {
  path: string;
  exact: boolean;
  text?: string;
  render: () => JSX.Element;
}

const newsRouters: Router[] = [
  {
    path: APP_URLS.NEWS_DETAIL1,
    exact: false,
    render: NewsDetail1,
  },
  {
    path: APP_URLS.NEWS_DETAIL2,
    exact: false,
    render: NewsDetail2,
  },
  {
    path: APP_URLS.NEWS_DETAIL3,
    exact: false,
    render: NewsDetail3,
  },
  {
    path: APP_URLS.NEWS_DETAIL4,
    exact: false,
    render: NewsDetail4,
  },
  {
    path: APP_URLS.NEWS_DETAIL5,
    exact: false,
    render: NewsDetail5,
  },
  {
    path: APP_URLS.NEWS_DETAIL6,
    exact: false,
    render: NewsDetail6,
  },
  {
    path: APP_URLS.NEWS_DETAIL7,
    exact: false,
    render: NewsDetail7,
  },
  {
    path: APP_URLS.NEWS_DETAIL8,
    exact: false,
    render: NewsDetail8,
  },
  {
    path: APP_URLS.NEWS_DETAIL9,
    exact: false,
    render: NewsDetail9,
  },
  {
    path: APP_URLS.NEWS_DETAIL10,
    exact: false,
    render: NewsDetail10,
  },
  {
    path: APP_URLS.NEWS,
    exact: false,
    render: NewsMainPart,
  },
];

export { newsRouters };
