export class TeamImage {
  // staff default
  static readonly staffDefault = require('./staff-default.png');
  static readonly staff0 = require('./staff0.png');
  static readonly staff1 = require('./staff1.png');
  static readonly staff2 = require('./staff2.png');
  static readonly staff3 = require('./staff3.png');
  static readonly staff4 = require('./staff4.png');
  static readonly staff5 = require('./staff5.png');
  static readonly staff6 = require('./staff6.png');
  static readonly staff7 = require('./staff7.png');
  static readonly staff8 = require('./staff8.png');
  static readonly staff9 = require('./staff9.png');
}
