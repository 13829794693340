/**
 * @description EJ dialog
 * @author zqsun
 * @date 2024/7/4
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import FlexCenter from 'react-flex-center';
import { TeamImage } from 'src/ts/img/teams/team-image';
import { useDialogStore } from 'src/ts/stores/dialog-store';
import DialogCloseIcon from 'src/tsx/views/components/atoms/DialogCloseIcon';
import styles from './Staff.module.scss';

const Staff2 = () => {
  /* state start*/
  const { setShowMember2Dialog } = useDialogStore();
  /* state end*/

  /* function start*/
  const closeDialog = () => {
    setShowMember2Dialog(false);
  };
  /* function end*/
  return (
    <div id='Staff2' className={styles.root}>
      <FlexCenter style={{ justifyContent: 'flex-end' }}>
        <DialogCloseIcon onClick={closeDialog} />
      </FlexCenter>

      <Grid
        container
        spacing={4}
        className={'h-full'}
        style={{
          alignItems: 'center',
          marginTop: '-50px',
        }}>
        {/* text part start*/}
        <Grid item xs={12} md={6} className={'h-full'}>
          <FlexCenter className={'h-full'}>
            <div className={'h-full'}>
              <article className={styles.article0}>
                大阪大学大学院工学研究科修了。大学在学中に起業し、アート系メディアの運営とアプリケーション開発に取り組む。新卒で富士フイルム株式会社に入社し、デジタルカメラ設計開発を担当。2019年、Artill株式会社を創業し、弊社に参画。アートを軸に企業や行政とのコラボレーションを手掛ける。これまで、大阪市との連携事業にて複数箇所でパブリックアート展示、ドイツ最高級家具ブランドとのアートコラボレーション、京都市文化財日本庭園にて陶芸・織物・いけばなのコラボレーションなどを企画し、TV・新聞・雑誌・webメディアなどに多数取り上げられる。
              </article>
              <div
                style={{
                  marginTop: '30px',
                }}>
                <span className={styles.position}>Director/ Art</span>
                <span
                  style={{
                    fontSize: '28px',
                    lineHeight: '42px',
                    marginLeft: '10px',
                  }}>
                  三輪昌徳
                </span>
              </div>
            </div>
          </FlexCenter>
        </Grid>
        {/* text part end*/}

        {/* photo part start*/}
        <Grid item xs={12} md={6}>
          <FlexCenter>
            <img src={TeamImage.staff2} alt={''} className={styles.staffImage0} />
          </FlexCenter>
        </Grid>
        {/* photo part end*/}
      </Grid>
    </div>
  );
};

export default Staff2;
