/**
 * @description massive map page
 * @author zqsun
 * @date 2024/7/4
 * @version 1.0
 */

import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import DebounceButton from 'react-debounce-button';
import FlexCenter from 'react-flex-center';
import { Link } from 'react-router-dom';
import TitlePath from 'src/ts/img/TitlePath';
import { ServiceImage } from 'src/ts/img/service/service-image';
import { APP_URLS } from 'src/ts/router/url-router';
import Footer from 'src/tsx/views/components/atoms/Footer';
import Header from 'src/tsx/views/components/atoms/Header';
import ScrollToTop from 'src/tsx/views/components/atoms/ScrollToTop';
import styles from './MassiveMapPage.module.scss';

const MassiveMapPage = () => {
  return (
    <div id='MassiveMapPage' className={styles.root}>
      <Header />
      <main
        id='HomepageMain'
        className={'homepageMain'}
        style={{
          background: 'rgba(242, 242, 242, 1)',
        }}>
        <nav className={'nav'}>
          <ul>
            <Link to={APP_URLS.DEFAULT_URL}>TOP</Link>
            <span>{'  >  '}</span>
            <span>MASSIVE MAP</span>
          </ul>
        </nav>
        <div className={clsx('mainRoot', styles.mapMain1)}>
          <Grid container>
            <Grid item xs={12} md={6} className={styles.mapMain1Text}>
              <h2 className={styles.title}>MASSIVE MAP</h2>
              <p>
                自分のデータ/趣味趣向等を入力するとAIがジェネレートし自分に合ったおすすめの観光スポットとルートを地図上に表示しユーザーの旅行を最適化。
              </p>
            </Grid>
            <Grid item xs={12} md={6}>
              <FlexCenter>
                <img src={ServiceImage.SERVICE_IMAGE14} alt={'MassiveMap'} />
              </FlexCenter>
            </Grid>
          </Grid>
        </div>
      </main>
      <div className={clsx('mainRoot', styles.mapMain2)}>
        <Grid
          container
          style={{
            alignItems: 'center',
          }}>
          <Grid item xs={12} md={6}>
            <FlexCenter>
              <img src={ServiceImage.SERVICE_IMAGE15} alt={'MassiveMap'} />
            </FlexCenter>
          </Grid>
          <Grid item xs={12} md={6}>
            <h2
              style={{
                fontSize: '20px',
                lineHeight: '30px',
                color: 'rgb(51, 198, 198)',
              }}>
              <TitlePath />
              MASSIVE MAPの特徴
            </h2>
            <h2
              style={{
                fontSize: '28px',
                lineHeight: '42px',
              }}>
              生成AIがあなたの旅行を最適化
            </h2>
            <p>
              自分のデータ/趣味趣向等を入力するとAIがジェネレートし自分に合ったおすすめの観光スポットとルートを地図上に表示しユーザーの旅行を最適化します。
            </p>
          </Grid>
        </Grid>
      </div>
      <div className={'mainRoot'}>
        <Grid
          container
          style={{
            paddingBottom: 20,
          }}>
          <Grid
            item
            xs={12}
            md={3}
            style={{
              marginBottom: 10,
            }}>
            <div className={styles.mapPaperOne}>
              <FlexCenter>
                <img
                  src={ServiceImage.SERVICE_IMAGE5}
                  alt={'MassiveMap'}
                  style={{
                    width: '95px',
                    height: '84px',
                  }}
                />
              </FlexCenter>
              <p className={styles.paperTitle}>移動データ</p>
              <p className={styles.paperText}>マップ上でスポットまでの経路表示全世界でシームレスに使用可能。</p>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            style={{
              marginBottom: 10,
            }}>
            <div className={styles.mapPaperOne}>
              <FlexCenter>
                <img
                  src={ServiceImage.SERVICE_IMAGE6}
                  alt={'MassiveMap'}
                  style={{
                    width: '82px',
                    height: '65px',
                  }}
                />
              </FlexCenter>
              <p className={styles.paperTitle}>決済データ</p>
              <p className={styles.paperText}>飲食・ホテル・ショップなど、アプリ内で予約から決済までワンストップ。</p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            style={{
              marginBottom: 10,
            }}>
            <div className={styles.mapPaperOne}>
              <FlexCenter>
                <img
                  src={ServiceImage.SERVICE_IMAGE7}
                  alt={'MassiveMap'}
                  style={{
                    width: '42px',
                    height: '87px',
                  }}
                />
              </FlexCenter>
              <p className={styles.paperTitle}>趣味嗜好データ</p>
              <p className={styles.paperText}>個人の趣味嗜好に合わせてお気に入りの場所をスポット表示。（位置連動型）</p>
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            style={{
              marginBottom: 10,
            }}>
            <div className={styles.mapPaperOne}>
              <FlexCenter>
                <img
                  src={ServiceImage.SERVICE_IMAGE8}
                  alt={'MassiveMap'}
                  style={{
                    width: '69px',
                    height: '79px',
                  }}
                />
              </FlexCenter>
              <p className={styles.paperTitle}>ユーザアカウントデータ</p>
              <p className={styles.paperText}>ユーザーの性別、年齢、住所、年収等のデータが取得可能。</p>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className={'mainRoot'}>
        <Grid container>
          <Grid item xs={12} md={7}>
            <p
              style={{
                fontSize: '20px',
                lineHeight: '30px',
                color: 'rgb(51, 198, 198)',
              }}>
              <TitlePath />
              MASSIVE Payの特徴
            </p>
            <h2
              className={styles.paperTitle}
              style={{
                fontWeight: 'bold',
                textAlign: 'left',
                marginBottom: '20px',
              }}>
              全世界でシームレスに使用可能
            </h2>

            <p className={styles.paperText}>報酬は現金換金 / カード決済に利用することが可能</p>
            <p
              className={styles.paperText}
              style={{
                marginBottom: '20px',
              }}>
              （ステーブルコインも将来的に使用可能に）
            </p>
            <p
              className={styles.paperText}
              style={{
                marginBottom: '10px',
              }}>
              GETしたコインを世界200カ国以上、6,800万店以上のVISA加盟店にて利用することが可能
            </p>
            <Grid container spacing={2} marginBottom={4}>
              <Grid item xs={12} md={6}>
                <img src={ServiceImage.SERVICE_IMAGE9} alt={'Massive Pay'} />
              </Grid>
              <Grid item xs={12} md={6}>
                <p
                  className={styles.paperText}
                  style={{
                    marginBottom: '5px',
                  }}>
                  発行元:VISA
                </p>
                <p
                  className={styles.paperText}
                  style={{
                    marginBottom: '5px',
                  }}>
                  カードタイプ:プリペイドカード
                </p>
                <p
                  className={styles.paperText}
                  style={{
                    marginBottom: '5px',
                  }}>
                  カード発行費:無料
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            <FlexCenter>
              <img src={ServiceImage.SERVICE_IMAGE10} alt={'MassiveMap'} className={styles.mapPhone} />
            </FlexCenter>
          </Grid>
        </Grid>
      </div>

      <FlexCenter className={styles.footTextMain}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <FlexCenter>
              <p className={styles.footText}>観光マップ事業についてのご依頼・お問い合わせはこちら</p>
            </FlexCenter>
          </Grid>
          <Grid item xs={12} md={4}>
            <FlexCenter>
              <Link to={APP_URLS.CONTACT}>
                <DebounceButton className={styles.footButton}>MORE</DebounceButton>
              </Link>
            </FlexCenter>
          </Grid>
        </Grid>
      </FlexCenter>
      <Footer />
      <ScrollToTop />
    </div>
  );
};

export default MassiveMapPage;
