/**
 * @description news detail 9
 * @author zqsun
 * @date 2024/7/22
 * @version 1.0
 */

import FlexCenter from 'react-flex-center';
import { Link } from 'react-router-dom';
import { NewsImage } from 'src/ts/img/news/news-image';
import { APP_URLS } from 'src/ts/router/url-router';
import styles from './NewsDetail.module.scss';

const NewsDetail9 = () => {
  return (
    <div id='NewsDetail9' className={styles.root}>
      <div className={'mainRoot'}>
        <h2 className={styles.title}>News</h2>
      </div>
      <div
        style={{
          backgroundColor: 'white',
        }}>
        <div
          className={'mainRoot'}
          style={{
            paddingTop: '50px',
            paddingBottom: '50px',
          }}>
          <p className={'font-bold mb-1.5'}>2023.5.23</p>
          <h1 className={styles.newsTitle}>株式会社エフェクチュアルとの業務提携に関するお知らせ</h1>

          <article className={styles.newsText}>
            株式会社エフェクチュアル（本社：東京都港区、代表取締役CEO：田中倫明）と業務提携を締結したことをお知らせ致します。業務提携を通じて店舗事業者のマーケティング活動のDX化をサポートし、集客力の向上に貢献していきます。
            <br />
            <a
              style={{
                color: 'blue',
                textDecoration: 'underline',
              }}
              href={'https://prtimes.jp/main/html/rd/p/000000122.000039683.html'}
              target={'_blank'}
              rel={'noreferrer'}>
              https://prtimes.jp/main/html/rd/p/000000122.000039683.html
            </a>
          </article>

          <FlexCenter style={{ justifyContent: 'space-evenly' }}>
            <Link to={APP_URLS.NEWS_DETAIL8}>
              <FlexCenter>
                <img src={NewsImage.newsPrev} alt='news' />
                <p
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}>
                  前ページ
                </p>
              </FlexCenter>
            </Link>
            <div>
              <Link to={APP_URLS.NEWS}>記事一覧</Link>
            </div>
            <Link to={APP_URLS.NEWS_DETAIL10}>
              <FlexCenter>
                <p
                  style={{
                    marginLeft: '10px',
                    marginRight: '10px',
                  }}>
                  次ページ
                </p>
                <img src={NewsImage.newsNext} alt='news' />
              </FlexCenter>
            </Link>
          </FlexCenter>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail9;
