/**
 * @description Header组件。包含登出功能
 * @author zqsun
 * @date 2023/09/26
 * @version 1.0
 */

import { useState } from 'react';
import DebounceButton from 'react-debounce-button';
import FlexCenter from 'react-flex-center';
import { Link, useHistory } from 'react-router-dom';
import MassiveLogo from 'src/ts/img/MassiveLogo';
import { APP_URLS } from 'src/ts/router/url-router';
import styles from './Header.module.scss';

interface HeaderProps {
  from?: 'Art';
}
const Header = ({ from }: HeaderProps) => {
  /* constant start*/
  const history = useHistory();
  /* constant end*/
  /* state start*/
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  /* state end*/

  /* function start*/
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = (anchor: string) => {
    if (location.pathname === APP_URLS.DEFAULT_URL) {
      document.querySelector(anchor)?.scrollIntoView({ behavior: 'smooth' });
    } else {
      history.push(`${APP_URLS.DEFAULT_URL}${anchor}`);
    }
    setMenuOpen(false); // 关闭菜单
  };
  /* function end*/

  return (
    <div
      className={styles.root}
      style={{
        justifyContent: 'space-between',
      }}
      data-from={from}>
      <FlexCenter>
        <Link to={APP_URLS.DEFAULT_URL}>
          <MassiveLogo />
        </Link>
      </FlexCenter>

      <FlexCenter className={styles.menuPC}>
        <Link to={APP_URLS.DEFAULT_URL} className={styles.link} data-from={from}>
          TOP
        </Link>
        <a
          href={'#ServicePart'}
          className={styles.link}
          onClick={e => {
            e.preventDefault();
            handleLinkClick('#ServicePart');
          }}
          data-from={from}>
          SERVICES
        </a>
        <Link to={APP_URLS.NEWS} className={styles.link} data-from={from}>
          NEWS
        </Link>
        <Link to={APP_URLS.ABOUT_US} className={styles.link} data-from={from}>
          ABOUT US
        </Link>
        <Link to={APP_URLS.CONTACT} className={styles.link} data-from={from}>
          CONTACT
        </Link>
      </FlexCenter>

      <DebounceButton className={styles.menuButton} onClick={toggleMenu}>
        ☰
      </DebounceButton>

      {menuOpen && (
        <div className={styles.menuMB}>
          <Link to={APP_URLS.DEFAULT_URL} className={styles.link}>
            TOP
          </Link>
          <a
            href='#ServicePart'
            className={styles.link}
            onClick={e => {
              e.preventDefault();
              handleLinkClick('#ServicePart');
            }}>
            SERVICES
          </a>
          <Link to={APP_URLS.NEWS} className={styles.link}>
            NEWS
          </Link>
          <Link to={APP_URLS.ABOUT_US} className={styles.link}>
            ABOUT US
          </Link>
          <Link to={APP_URLS.CONTACT} className={styles.link}>
            CONTACT
          </Link>
        </div>
      )}
    </div>
  );
};
export default Header;
